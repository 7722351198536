<template>
  <div class="vitem vitem-vertical" :class="{
    'vitem-live' : item.isLive(),
    'vitem-cancel': item.isCancel(),
  }">
    <custom-link :to="{ name: 'match', params: { id: item.id, slug: item.slug } }" class="match-link" v-if="!item.isCancel()"></custom-link>
    <div class="vitem-info text-center" v-if="item.match_status==='live'">
      <div class="badge-live" style="margin: 0 auto 8px;"><i class="dot"></i>Live</div>
      <div class="time-loaded">{{ item.time_str }}</div>
      <div class="detail"  v-if="item.commentators && item.commentators.length > 0" ><i class="fas fa-microphone mr-2"></i>{{ item.casterName() }}</div>
    </div>
    <div class="vitem-info text-center" v-else-if="item.match_status==='pending'">
      <div class="badge-live" style="margin: 0 auto 8px;" v-if="item.isLive()"><i class="dot"></i>Live</div>
      <div class="status">{{ item.showTime() }}</div>
      <div class="detail">{{ item.showDate() }}</div>
    </div>
    <div class="vitem-info text-center" v-else>
      <div class="status" v-if="item.match_status==='canceled'">Đã Hủy</div>
      <div class="status" v-else-if="item.match_status==='delay'">Hoãn Lại</div>
      <div class="status" v-else-if="item.match_status==='postponed'">Hoãn Lại</div>
      <div class="status" v-else-if="item.match_status==='interrupted'">Gián Đoạn</div>
      <div class="time-loaded end" v-else>Hết giờ</div>
      <div class="detail">
        <div>{{ item.showDate() }}</div>
        <div>{{ item.showTime() }}</div>
      </div>
    </div>
    <div class="item-team">
      <div class="team team-home">
        <div class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.home.logo"></div>
        <h3 class="team-name">{{ item.home.name }}</h3>
        <div class="vitem-card ml-3">
          <div class="xcard xcard-red" v-for="card in item.home_red_cards_arr" :key="card"></div>
        </div>
      </div>
      <div class="team team-away">
        <div class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.away.logo"></div>
        <h3 class="team-name">{{ item.away.name }}</h3>
        <div class="vitem-card ml-3">
          <div class="xcard xcard-red" v-for="card in item.away_red_cards_arr" :key="card"></div>
        </div>
      </div>
    </div>
    <div class="item-result">
      <div class="ir-col">
        <div>{{ item.showHomeScore() }}</div>
        <div>{{ item.showAwayScore() }}</div>
      </div>
    </div>
    <div class="item-buttons">
      <div classw="ibs-live">
        <custom-link :to="{ name: 'match', params: { id: item.id, slug: item.slug } }" class="btn btn-sm" >Trực tiếp</custom-link>
      </div>
      <div class="ibs-bet"><a :href="getBtnAdsBet()" target="_blank" rel="nofollow" class="btn btn-sm btn-betnow">Đặt cược</a></div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: "MatchFixtureItem",
  props: [
    "model"
  ],
  computed: {
    item() {
      return this.$store.state.match.matches["id_" + this.model.id];
    },
  },
}
</script>

<style scoped>

</style>