// import store from './match'
// export default store
import match from './modules/match'
import tournament from './modules/tournament'
import asd from './modules/asd'
import auth from './modules/auth'

import {createStore} from "vuex";

const store = createStore({
    modules: {
        match, auth, tournament, asd
    }
})
export default store