<template>
  <div class="ajax-loading is-load-list is-load-host">
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
  </div>
</template>

<script>
export default {
  name: "BetHostLoading",
}
</script>
