<template>
  <iframe loading="lazy" :src="tracker_url" style="width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;">
    Your browser doesn't support iframes
  </iframe>
</template>

<script>
export default {
  name: "MatchLiveTracker",
  props:[
      'tracker_url'
  ]
}
</script>

<style scoped>

</style>