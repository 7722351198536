<template>
  <!--Begin: Main-->
  <div id="main-wrapper" v-if="user">
    <div class="container">
      <!-- Begin: user -->
      <div class="box box-user">
        <div class="bu-header">
          <div class="u-name">{{ user.name }}</div>
          <div class="u-menu">
            <div class="um-li active"><a href="javascript:void(0)">Tài Khoản</a></div>
          </div>
        </div>
        <div class="bu-content">
          <div class="alert pre-alert alert-danger" id="block-err-msg" v-if="message_error">{{ message_error }}</div>
          <div class="alert pre-alert alert-success" id="block-success-msg" v-if="message_success">
            {{ message_success }}
          </div>
          <div class="buc-profile">
            <form class="vbform vbform-dark" action="()">
              <div class="fg-avatar">
                <div id="select-avatar" data-toggle="modal" data-target="#modalavatar">
                  <div class="user-avatar mb-2">
                    <div class="avatar-loading is-loading" style="display: none">
                      <div></div>
                    </div>
                    <img loading="lazy" :src="user.avatar">
                  </div>
                  <div class="text">Đổi Avatar</div>
                </div>
              </div>

              <div class="form-group">
                <label class="prelabel" for="pro5-email">Email đăng ký</label>
                <input type="email" class="form-control" disabled="" id="pro5-email" :value="user.email">
              </div>
              <div class="form-group" v-if="edit_name">
                <label class="prelabel">Tên hiển thị</label>
                <div class="vbf-action vbf-edit">
                  <input type="text" class="form-control a-left" v-model="name_value">
                  <div class="vbf-a-btn a-right" @click="updateName"><i class="fas fa-check highlight"></i></div>
                  <div class="vbf-a-btn a-right cancel" @click="toggleEditName"><i class="fas fa-times"></i></div>
                </div>
              </div>
              <div class="form-group" v-else>
                <label class="prelabel" for="pro5-name">Tên hiển thị</label>
                <div class="vbf-action">
                  <input type="text" class="form-control a-left" disabled="" id="pro5-name" :value="user.name">
                  <div class="vbf-a-btn a-right" @click="toggleEditName"><i class="fas fa-pen"></i></div>
                </div>
              </div>
              <div class="form-group">
                <label class="prelabel" for="pro5-join">Ngày tham gia</label>
                <input type="text" class="form-control" disabled="" id="pro5-join" :value="user.local_join_date">
              </div>
              <div class="form-group">
                <label class="prelabel" for="pro5-name">Mật khẩu</label>
                <div class="div-formgroup" data-toggle="modal" data-target="#modal-form-change-pass"><i class="fas fa-key mr-2"></i>Đổi
                  mật khẩu
                </div>
              </div>
              <!-- <div class="form-group mt-5">
                <a href="#" class="btn vb-btn btn-block btn-primary">Lưu lại</a>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <!--/ End: user -->
    </div>
  </div>
  <!--End: Main-->
  <div class="modal fade vbmodal vbmodal-dark vbmodal-form" id="modal-form-change-pass" tabindex="-1" role="dialog"
       aria-labelledby="modalhistorytitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="pop-title mb-4">Đổi mật khẩu</h5>
          <form id="form-change-pass" class="vbform vbform-dark" action="()">
            <div class="alert pre-alert alert-danger" v-if="message_password_error">{{message_password_error}}</div>
            <div class="form-group">
              <label class="prelabel">Mật khẩu hiện tại</label>
              <input type="password" class="form-control" v-model="old_password" required id="txt-pass">
            </div>
            <div class="form-group">
              <label class="prelabel">Mật khẩu mới</label>
              <input type="password" class="form-control" v-model="new_password" required id="txt-new-pass">
            </div>
            <div class="form-group">
              <label class="prelabel">Nhập lại mật khẩu mới</label>
              <input type="password" class="form-control" v-model="re_password" required id="txt-re-pass">
            </div>
            <div class="form-group mt-5">
              <button class="btn vb-btn btn-block btn-primary" type="button" @click="changePassword">
                Lưu mật khẩu
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade vbmodal vbmodal-dark vbmodal-avatar" id="modalavatar" tabindex="-1" role="dialog"
       aria-labelledby="modalhistorytitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
<!--          <h5 class="pop-title mb-4">Chọn avatar</h5>-->
          <div class="d-flex align-items-center mb-4">
            <h5 class="pop-title mb-0 mr-3">Chọn avatar</h5>
            <button type="file" class="btn btn-sm btn-radius btn-light">
              <label style="margin: 0px">
                <i class="fas fa-upload mr-2"></i>Upload
                <input
                    @change="uploadAvatar"
                    id="avatar-uploader"
                    accept=".jpg,.jpeg,.gif,.png"
                    type="file"
                    style="position: fixed; top: -100%"
                >
              </label>

            </button>
          </div>
          <ul class="nav nav-tabs pre-tabs pre-tabs-min pre-tabs-hashtag mb-4">
            <li class="nav-item"><a data-toggle="tab" href="#player-avatar" class="nav-link active" title="">Cầu thủ</a>
            </li>
            <li class="nav-item"><a data-toggle="tab" href="#club-avatar" class="nav-link" title="">Câu lạc bộ</a></li>
            <li class="nav-item"><a data-toggle="tab" href="#country-avatar" class="nav-link" title="">Quốc gia</a></li>
          </ul>
          <div class="tab-content">
            <div id="player-avatar" class="tab-pane fade show active">
              <div class="avatar-list">
                <div class="item" :class="{
                  active : isActive(item)
                }" v-for="item in player_avatars" :key="item" @click="setAvatar(item)">
                  <div class="profile-avatar profile-avatar-choice">
                    <img loading="lazy" :src="item">
                  </div>
                </div>
              </div>
            </div>
            <div id="club-avatar" class="tab-pane fade">
              <div class="avatar-list">
                <div class="item" :class="{
                  active : isActive(item)
                }" v-for="item in club_avatars" :key="item" @click="setAvatar(item)">
                  <div class="profile-avatar profile-avatar-choice">
                    <img loading="lazy" :src="item">
                  </div>
                </div>
              </div>
            </div>
            <div id="country-avatar" class="tab-pane fade">
              <div class="avatar-list">
                <div class="item" :class="{
                  active : isActive(item)
                }" v-for="item in country_avatars" :key="item" @click="setAvatar(item)">
                  <div class="profile-avatar profile-avatar-choice">
                    <img loading="lazy" :src="item">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <button type="button" aria-label="Close" class="btn vb-btn btn-block btn-primary" @click="updateAvatar">Lưu avatar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "jquery";
import {mapState} from "vuex";
import UserService from "@/services/UserService";


function buildListAvatar(data, prefix, limit) {
  for (let i = 1; i <= limit; i++) {
    let name = `${i}.png`
    if (i < 10) {
      name = `0${i}.png`
    }
    data.push(`${prefix}_${name}`)
  }
  return data
}


export default {
  name: "ProfilePage",
  data() {
    const player_avatars = buildListAvatar(
        ['/images/avatar/default.png'],
        '/images/avatar/player',
        49
    )
    const club_avatars = buildListAvatar(
        [],
        '/images/avatar/club',
        20
    )
    const country_avatars = buildListAvatar(
        [],
        '/images/avatar/country',
        38
    )
    return {
      name_value: '',
      avatar_value: null,
      old_password: '',
      new_password: '',
      re_password: '',

      edit_name: false,
      message_success: null,
      message_error: null,
      message_password_error: null,
      player_avatars,
      club_avatars,
      country_avatars,
    }
  },

  watch: {
    user(value, old) {
      if (!value && old) {
        return this.$router.push({name: 'home'})
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),

  },
  mounted() {
    this.name_value = this.user.name
    this.avatar_value = this.user.avatar
  },
  methods: {
    isActive(item) {
      if (this.avatar_value && this.avatar_value.indexOf(item) >= 0) {
        return true
      }
      return false
    },
    setAvatar(value) {
      this.avatar_value = value
    },
    commitUser(user) {
      this.$store.dispatch('auth/login', {
        user,
      })
    },
    showMessage(message, isError = true) {
      if (isError) {
        this.message_success = null
        this.message_error = message

      } else {
        this.message_success = message
        this.message_error = null
      }
      if(this.message_success){
        $('#block-success-msg').slideDown()
        setTimeout(function () {
          $('#block-success-msg').slideUp()
        }, 10000)
      }
    },
    toggleEditName() {
      this.name_value = this.user.name
      this.edit_name = !this.edit_name
    },
    async updateName() {
      if (this.name_value === this.user.name) {
        this.toggleEditName()
        return
      }
      const result = await UserService.updateInfo({
        name: this.name_value
      })
      if (result.status === 1) {
        if (result.data.user) {
          this.commitUser(result.data.user)
          this.showMessage("Cập nhật thành công.", false)
          this.toggleEditName()
        }
      } else {
        this.showMessage(result.message)
      }

    },
    async uploadAvatar(){
      $("#modalavatar").modal("hide")
      const local = this
      var input = document.getElementById('avatar-uploader');
      if (input.files && input.files[0]) {
        var file = input.files[0];
        const fileType = file['type'];
        // const max_size = 0;
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(fileType)) {
          local.showMessage("Định dạng file không đúng.", true)
          return;
        }
        // if (file.size > max_size){
        //     return;
        // }
        var form = new FormData();
        form.append("image", file);
        $('.avatar-loading').show();
        $.ajax({
          "url": "https://api.fbhub.xyz/upload",
          "method": "POST",
          "processData": false,
          "mimeType": "multipart/form-data",
          "contentType": false,
          "dataType": "json",
          "data": form,
          success: function (response) {
            $('.avatar-loading').hide();
            if(response.success && response.data.link){
              var image_url = response.data.link;
              local.avatar_value = image_url
              local.updateAvatar()
            }else {
              local.showMessage("Không thể upload ảnh.", true);
            }
            console.log("response", response);
          },
          error: function (err) {
            $('.avatar-loading').hide();
            console.error(err);
            local.showMessage("Không thể upload ảnh.", true);
          }
        });
      }
    },
    async updateAvatar() {

      const result = await UserService.updateInfo({
        avatar: this.avatar_value
      })
      if (result.status === 1) {
        if (result.data.user) {
          this.commitUser(result.data.user)
          this.showMessage("Cập nhật thành công.", false)
          $("#modalavatar").modal("hide")
        }
      } else {
        this.showMessage(result.message)
      }
    },
    async changePassword() {
      this.message_password_error = null
      if(this.old_password.length < 6){
        this.message_password_error = "Mật khẩu hiện tại không đúng"
        return
      }
      if (this.new_password.length < 6) {
        this.message_password_error = "Mật khẩu phải có ít nhất 6 ký tự!"
        return
      }
      if (this.new_password !== this.re_password) {
        this.message_password_error = "Mật khẩu nhập lại không trùng khớp!"
        return
      }
      const result = await UserService.changePassword(this.old_password, this.new_password)
      if (result.status === 1) {
        this.new_password = ''
        this.re_password = ''
        this.old_password = ''
        this.showMessage("Đổi mật khẩu thành công.", false)
        $("#modal-form-change-pass").modal("hide")

      } else {
        this.message_password_error = result.message
      }
    }
  }
}
</script>

<style scoped>

</style>