<template>
  <div class="com-block">
    <div class="com-header" style="padding:0">
      <div class="heading"><i class="fas fa-list-ul mr-3"></i>KẾT QUẢ</div>
    </div>
    <div v-if="results" >
      <div class="match_box" v-for="item in results" :key="item.date">
        <div class="mb_-header">
          <strong>{{item.date_show}}</strong>
        </div>
        <div class="match_list match_list-list">
          <MatchResultItem v-for="(match, index) in item.data" :model="match" :key="index"></MatchResultItem>
        </div>
      </div>
    </div>
    <FixtureLoading v-else></FixtureLoading>
  </div>
</template>
<script>
import MatchResultItem from "@/components/tournament/result/MatchResultItem";
import FixtureLoading from "@/components/loading/FixtureLoading";
import {mapState, useStore} from "vuex";

export default {
  name: "TournamentResult",
  props: [
    'model_id'
  ],
  components: {
    MatchResultItem,
    FixtureLoading,
  },

  computed: mapState({
    results : state => state.tournament.results,
  }),
  watch:{
    model_id(value){
      if(value){
        this.$store.dispatch('tournament/fetchDateResult',value)
      }
    }
  },
  setup(props){
    const store = useStore()

    /*if(!store.state.tournament.fixtures || store.state.tournament.fixtures.length === 0){
      store.dispatch('tournament/fetchDateResult',store.state.tournament.detail.id)
    }*/

    store.dispatch('tournament/fetchDateResult', props.model_id)

    /*const route = useRoute();
    return{
      slug : route.params.slug,
      modelId : route.params.id,
    }*/
  },
}
</script>
