import * as _ from 'lodash'
export function group_by_tournament(matches) {
    let tournament_matches_tmp = _.groupBy(matches, 'tournament.unique_tournament.id')
    let tournament_matches = []
    _.forIn(tournament_matches_tmp, (matches) => {
        let tournament = matches[0].tournament
        tournament.is_featured = (tournament.unique_tournament && tournament.unique_tournament.is_featured) || false
        tournament_matches.push({
            tournament: tournament,
            matches: _.orderBy(matches, ['timestamp'], ['asc'])
        })
    })
    tournament_matches = _.orderBy(tournament_matches, ['tournament.is_featured','tournament.priority', 'tournament.name'], ['desc' ,'desc', 'asc']);
    return tournament_matches
}

export function group_by_date(matches, order = 'desc') {
    let matches_by_date = {};
    matches.map(item => {
        if(!matches_by_date[item.date]){
            matches_by_date[item.date] = {
                timestamp: item.timestamp,
                date: item.date,
                date_show: item.showDate(),
                data : []
            }
        }
        (matches_by_date[item.date]['data']).push(item)
    })
    matches_by_date = Object.values(matches_by_date)
    matches_by_date = _.orderBy(matches_by_date, ['timestamp'], [order]);
    return matches_by_date
}
