<template :key="route_name">
  <div v-if="ads && ads.row3" class="vbsp-list vebo-sp">
    <div v-for="(item,i) in ads.row3" :key="i" :class="item.class">
      <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
        <img rel="nofollow" loading="lazy" :src="item.src"/></a>
    </div>
  </div>
  <div v-else class="vebo-sp">
    <div class="mmo">
      <div class="mmo-inner size-728x90"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsRow3",
  data(){
    return {route_name: this.$route.name}
  },
  computed: {
    ads() {
      if(this.$route.name === 'match'){
        return this.$store.state.asd.live;
      }else{
        return this.$store.state.asd.home;
      }
    }
  },
  mounted() {
  }
}
</script>
