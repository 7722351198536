import {UserModel} from "@/store/models/user";
import ApiService from "@/services/ApiService";

export default {
    namespaced: true,
    state:{
        user     : null,
        chat_urls: null,
        chat_token: null,
    },
    mutations: {
        setUser(state, user) {
            if(user == null){
                state.user = null
            }else {
                state.user = new UserModel(user);
            }
            ApiService.setHeader()
        },
        setChatUrls(state, data) {
            state.chat_urls = data || null;
        },
        setChatToken(state, data) {
            state.chat_token = data || null;
        },

    },
    actions: {
        setChatUrls({commit}, payload){
            commit('setChatUrls', payload)
        },
        setChatToken({commit}, payload){
            commit('setChatToken', payload)
        },
        login({commit}, payload){
            commit('setUser', payload.user)
        },
        logout({commit}){
            commit('setUser', null)
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.user;
        },
        chatUrls(state) {
            if(state.chat_urls){
                return state.chat_urls;
            }
            let def = 'https://www6.cbox.ws/box/?boxid=827198&boxtag=wskql9';
            if(window.default_chat_url){
                def = window.default_chat_url
            }
            return {
                default   : def,
                football  : window.chat_url_football || def,
                basketball: window.chat_url_basketball || def,
                event     : window.chat_url_event || def,
                other     : window.chat_url_other || def,
            }
        },
        chatUrlsVebo(state) {
            return `https://chat.vebotv.me?token=${encodeURIComponent(state.chat_token||'')}&room=`
        },
    },
}