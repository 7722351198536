<template>
  <!--Begin: Main-->
  <div id="main-wrapper" v-if="item">
    <div class="container">
      <ul class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
        <li class="breadcrumb-item" itemscope itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <a itemscope itemtype="https://schema.org/WebPage" itemprop="item" :itemid="'/'" :href="domain_origin + '/'">
            <span itemprop="name">Trang chủ</span>
          </a>
          <meta itemprop="position" content="1" />
        </li>
        <li class="breadcrumb-item" itemscope itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <a itemscope itemtype="https://schema.org/WebPage" itemprop="item" :itemid="$router.resolve({ name: 'fixture_day', params: { slug: 'ngay-'+item.showDateSlug() } }).href" :href="domain_origin + $router.resolve({ name: 'fixture_day', params: { slug: 'ngay-'+item.showDateSlug() } }).href">
            <span itemprop="name">Lịch thi đấu {{ item.showDate() }}</span>
          </a>
          <meta itemprop="position" content="2" />
        </li>
        <li class="breadcrumb-item" itemscope itemprop="itemListElement" itemtype="https://schema.org/ListItem">
          <a itemscope itemtype="https://schema.org/WebPage" itemprop="item" :itemid="$router.resolve({ name: 'match', params: { id: item.id, slug: item.slug } }).href" :href="domain_origin + $router.resolve({ name: 'match', params: { id: item.id, slug: item.slug } }).href">
            <span itemprop="name">{{ item.name }} {{ item.showTime() }} ngày {{ item.showDate() }}</span>
          </a>
          <meta itemprop="position" content="3" />
        </li>
      </ul>
    </div>

    <AdsRow1></AdsRow1>

    <div class="container">
      <div class="box box-match">
        <div class="box-header">
          <h1 class="headlineA1">{{ item.name }} {{ item.showTime() }} ngày {{ item.showDate() }}</h1>
          <div id="content_top" style="margin:8px 0;"></div>
        </div>
<!--        <div class="m-social w-social mb-3">
          <div class="social-block">
            <div class="sb-text">Chia sẻ</div>
            <div class="addthis_inline_share_toolbox"></div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>-->
        <div class="box-content">
          <div class="match-box">
            <div class="match-detail">
              <div class="item">
                <div class="team team-home">
                  <a href="javascript:;" class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.home.logo" onerror="this.src='/images/1.gif';"></a>
                  <h3 class="team-name"><a href="javascript:;" title=""><span class="v-center">{{ item.home.name }}</span></a></h3>
                </div>
                <div class="item-info">
                  <custom-link class="item-league" v-if="item.tournament.unique_tournament" :to="{ name: 'league_overview', params: { slug: item.tournament.unique_tournament.slug, id: item.tournament.unique_tournament.id } }">
                    <i class="league-icon mr-2"><img loading="lazy" :src="item.tournament.logo"></i>{{ item.tournament.name }}
                  </custom-link>
                  <span class="item-league" v-else>-</span>

                  <div class="result"><span>{{ item.scores.home }}</span><span>-</span><span>{{item.scores.away}}</span></div>
                  <div class="time-loaded"><span>{{ item.time_str }}</span></div>
                </div>
                <div class="team team-away">
                  <a href="javascript:;" class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.away.logo" onerror="this.src='/images/1.gif';"></a>
                  <h3 class="team-name"><a href="javascript:;" title=""><span class="v-center">{{ item.away.name }}</span></a></h3>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="match-content">
              <MatchPlayer></MatchPlayer>
<!--              <div class="match-menu">
                <ul class="no-bullets">
                  <li class="active"><a href="match.html" class="mm-link">Trực tiếp</a></li>
                  <li><a href="match-stats.html" class="mm-link">Thống kê</a></li>
                  <li><a href="match-lineup.html" class="mm-link">Đội hình</a></li>
                  <li><a href="match-table.html" class="mm-link">Xếp hạng</a></li>
                  <li><a href="match-history.html" class="mm-link">Lịch sử</a></li>
                  <li><a href="match-news.html" class="mm-link">Soi kèo</a></li>
                </ul>
              </div>-->
              <div class="mc_padding">
                <div class="mw-w-sidebar is-page">
                  <div class="main-left">
                    <div class="box box-fact mb-0">
                      <div class="box-content">
                        <div class="match_fact">
                          <div class="item item-info mb-1">
                            <div class="ii-02 mb-2"><span class="title">Thời gian diễn ra:</span> {{ item.showDateText() }} </div>
                            <div class="ii-02 mb-2" v-if="item.tournament.unique_tournament" >
                              <span class="title">Giải đấu:</span>
                              <custom-link :to="{ name: 'league_overview', params: { slug: item.tournament.unique_tournament.slug, id: item.tournament.unique_tournament.id } }">
                                {{ item.tournament.name }}
                              </custom-link>
                            </div>
                          </div>
                          <div class="m-match-vs">
                            <div class="team team-home">
                              <div class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.home.logo"></div>
                            </div>
                            <div class="item-info">
                              <div class="status mb-0"><span>VS</span></div>
                            </div>
                            <div class="team team-away">
                              <div class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.away.logo"></div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="sidebar-right">
                    <BetHostBig></BetHostBig>
                    <AdsTable1></AdsTable1>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content_bot" style="text-align: center; margin: 10px 0 30px"></div>
    </div>
  </div>
  <!--End: Main-->
</template>

<script>
import {useRoute} from "vue-router";
import MatchPlayer from "@/components/match/MatchPlayer";
import BetHostBig from "@/components/ads/BetHostBig";
import AdsTable1 from "@/components/ads/AdsTable1";
import AdsRow1 from "@/components/ads/AdsRow1";
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: "MatchPage",
  components:{
    MatchPlayer,
    BetHostBig,
    AdsTable1,
    AdsRow1,
  },
  setup(){
    const route = useRoute();
    return{
      slug : route.params.slug,
      modelId : route.params.id,
    }
  },
  created() {
    //let themejs = document.createElement('script')
    //themejs.setAttribute('src', 'https://cdn.peer2.network/peer2.jwplayer8.plugins.js')
    //document.head.appendChild(themejs)
    //let themejs2 = document.createElement('script')
    //themejs2.setAttribute('src', 'https://static.90pcdn.com/player/jwplayer.js')
    //document.head.appendChild(themejs2)
  },
  async mounted() {
    this.$store.dispatch('match/fetchDetail', this.modelId);

    await this.getMeta('match_live', this.$route.params.id)
  },
  computed: {
    ...mapState({
      detail: state => state.match.detail
    }),
    item() {
      const it = this.$store.state.match.matches["id_" + this.modelId] || null;
      if(it){
        const day = moment(it.timestamp).format('DD/MM/YYYY');
        const hour = moment(it.timestamp).format('HH:mm');
        document.title = `Trực tiếp ${it.name} online vào ${hour} ngày ${day} trên ${window.siteName}`;
      }
      return it;
    },
    domain_origin() {
      return window.location.origin
    }
  },
}
</script>

<style scoped>

</style>