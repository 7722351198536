<template>
  <div class="com-block">
    <div class="com-header" style="padding:0">
      <div class="heading" href="javascript:;"><i class="fas fa-futbol mr-3"></i>TRẬN CẦU TÂM ĐIỂM</div>
    </div>
    <div class="box-content">
      <div class="xitems xitems-grid xitems-grid-dual" v-if="featured">
        <MatchFeaturedItem v-for="item in featured" :key="item.id" :model-id="item.id"></MatchFeaturedItem>
      </div>
      <MatchFeaturedPlaceHolder v-else></MatchFeaturedPlaceHolder>
    </div>
  </div>
<!--  <div class="com-block">
    <div class="com-header" style="padding:0">
      <div class="heading"><i class="fas fa-quote-left mr-3"></i>Giới Thiệu</div>
    </div>
    <div class="com-about">
      <p>Giải bóng đá Ngoại hạng Anh (tiếng Anh: Premier League), thường được biết đến với tên gọi English Premier League hoặc EPL, là hạng đấu cao nhất của hệ thống các giải bóng đá ở Anh. Gồm 20 câu lạc bộ, giải đấu sử dụng hệ thống thăng hạng và xuống hạng với English Football League (EFL). Mùa giải kéo dài từ tháng 8 đến tháng 5 với mỗi đội chơi 38 trận đấu (đấu với 19 đội khác trên sân nhà và sân khách). Đa số các trận đấu được diễn ra vào chiều Thứ Bảy và Chủ Nhật.</p>

      <p>Giải đấu được thành lập vào ngày 20 tháng 2 năm 1992 với tên gọi FA Premier League sau quyết định của các câu lạc bộ tham dự Football League First Division tách khỏi Football League, một giải đấu khởi nguồn từ năm 1888, nhằm tận dụng lợi thế về các thỏa thuận bản quyền truyền hình. Thỏa thuận trong nước trị giá 1 tỉ bảng/năm được ký cho mùa 2013–14, với việc BSkyB và BT Group giành quyền phát sóng lần lượt 116 và 38 trận đấu. Giải đấu thu về 2,2 tỉ euro/năm tiền bản quyền truyền hình trong nước và quốc tế. Tính đến mùa 2014–15, các câu lạc bộ được chia khoản lợi nhuận 1,6 tỉ bảng, và 2,4 tỉ bảng vào mùa 2016–17.</p>

      <p>Hiện tại, Premier League là giải đấu bóng đá được xem nhiều nhất trên thế giới, phát sóng trên 212 vùng lãnh thổ tới 643 triệu hộ gia đình và khoảng 4,7 tỉ khán giả truyền hình. Trong mùa giải 2014–15, trung bình 1 trận đấu tại Premier League thu hút khoảng 36,000 khán giả tới sân, cao thứ 2 trong các giải bóng đá chuyên nghiệp sau Bundesliga với 43.500 khán giả. Phần lớn các sân bóng đều được lấp đầy khán giả. Premier League xếp thứ 2 trong Hệ số UEFA dành cho các giải đấu dựa theo thành tích của các câu lạc bộ tại các giải đấu châu Âu trong 5 mùa giải, tính đến năm 2018.</p>

      <p>Đã có tất cả 48 câu lạc bộ của Anh và 2 câu lạc bộ của xứ Wales tham dự kể từ mùa giải đầu tiên của Premier League năm 1992, nhưng mới chỉ có 7 trong số đó giành được chức vô địch: Manchester United (13), Chelsea (5), Manchester City (5), Arsenal (3), Blackburn Rovers, Leicester City và Liverpool (1). Kỷ lục về số điểm nhiều nhất trong một mùa giải Premier League là 100, hiện được Manchester City nắm giữ vào mùa giải 2017–18.</p>
    </div>
  </div>-->
</template>
<script>
import MatchFeaturedItem from "@/components/tournament/match_featured/MatchFeaturedItem";
import MatchFeaturedPlaceHolder from "@/components/loading/MatchFeaturedPlaceHolder";
import {mapState, useStore} from "vuex";
export default {
  name: "TournamentOverview",
  props: [
      'model_id'
  ],
  components: {
    MatchFeaturedItem,
    MatchFeaturedPlaceHolder
  },
  watch:{
    model_id(value){
      if(value){
        this.$store.dispatch('tournament/fetchFeatured',value)
      }
    }
  },
  computed: mapState({
    featured : state => state.tournament.featured,
  }),
  setup(props){
    const store = useStore()
    store.dispatch('tournament/fetchFeatured',props.model_id)
  },

}
</script>
