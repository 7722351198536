<template>
  <div class="match_box">
    <div class="mb_-header">
      <custom-link class="item-league" v-if="tournament.unique_tournament" :to="{ name: 'league_overview', params: { slug: tournament.unique_tournament.slug, id: tournament.unique_tournament.id } }">
        <i class="league-icon mr-2"><img loading="lazy" :src="tournament.logo"></i>{{ tournament.name }}
      </custom-link>
      <span class="item-league" v-else>-</span>
    </div>
    <div class="match_list match_list-list">
      <MatchResultItem v-for="(item, index) in fixture" :model="item" :key="index"></MatchResultItem>
    </div>
  </div>
</template>

<script>
import MatchResultItem from "@/components/fixture/MatchFixtureItem";

export default {
  name: "TournamentFixture",
  props: [
    'tournament',
    'fixture',
  ],
  components: {
    MatchResultItem
  }
}
</script>

<style scoped>

</style>