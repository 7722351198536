<template>
  <div class="ajax-loading is-load-list">
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
  </div>
</template>

<script>
export default {
  name: "MatchResultLoading",
}
</script>
