<template>
  <div id="aw-body">
    <div id="main-home">
      <div class="container">
        <div class="mh-logo">
          <img :src="getGlobal('logo')" :title="getGlobal('siteName') + ' Xem Trực Tiếp Bóng Đá '+ getGlobal('domainName') +' Miễn Phí Nhanh Nhất'">
        </div>
        <div class="mh-top">
          <h1 class="headline-large">{{getGlobal('siteName')}}: xem trực tiếp bóng đá miễn phí tốt nhất</h1>
          <div class="description" id="content_top" style="margin:8px 0;">Thưởng thức dịch vụ xem trực tiếp bóng đá MIỄN PHÍ tốt nhất và nhanh nhất trên {{getGlobal('siteName')}}!</div>
          <div class="d-flex align-items-center mt-social">
            <div class="addthis_inline_share_toolbox"></div>
          </div>
          <div class="mh-buttons">
            <custom-link :to="{ name: 'home'}" class="sbtn sbtn-lg sbtn-primary">
              <span class="d-flex align-items-center">Xem ngay &rarr;</span>
            </custom-link>
          </div>
        </div>
        <div class="mh-copyright small">Copyright © {{(new Date()).getFullYear()}} by {{getGlobal('domainName')}}.</div>
      </div>
    </div>
  </div>
  <div id="content_bot" style="text-align: center; margin: 10px 0 20px"></div>
  <AboutBlock></AboutBlock>
</template>

<script>
import AboutBlock from "@/components/block/AboutBlock";
export default {
  name: "StartPage",
  components: {
    AboutBlock,
  },
  methods: {
    getGlobal(item = null) {
      if(item && window[item]){
        return window[item];
      }
    },
  },
  async mounted() {
    await this.getMeta(this.$route.name)
  },
}
</script>