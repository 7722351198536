<template :key="key">
  <div id="main-wrapper">
    <div class="container">
      <div class="box box-news box-news-detail">
        <div class="box-header">
          <h1 class="headlineA1" v-if="author && author.name">{{author.name}}</h1>
        </div>
        <div class="box-content">
          <div class="mw-w-sidebar is-basic">
            <div class="read-news box-wpd box-wbg">
              <div class="article-news">
                <div class="row">
                  <div class="col-6 an-social mb-3">
                    <div class="social-block">
                      <div class="addthis_inline_share_toolbox"></div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-6"></div>
                </div>

                <div v-if="author && author.content" v-html="author.content"></div>
              </div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import ApiService from "@/services/ApiService";
// import AdsRow1 from "@/components/ads/AdsRow1";
// import BetHostSmall from "@/components/ads/BetHostSmall";
// import AdsTable1 from "@/components/ads/AdsTable1";
// import NewsGridList from "@/components/news/NewsGridList";
// import NewsListList from "@/components/news/NewsListList";

export default {
  name: "AuthorDetailPage",
  components: {
  },
  data(){
    return{
      key: 'author_'+this.$route.params.slug,
      news: null,
      news_created: null,
      data_related: [],
      data_hot: [],
    }
  },

  async mounted() {
    const response = await ApiService.get(`/api/news/author/detail/${this.$route.params.slug}`)
    if(response && response.data && response.data.data){
      this.author = response.data.data
    }
  },
  watch: {
    $route(route_to){
      this.key = 'author_'+route_to.params.slug
    },
    async key(value) {
      if (value) {
        const response = await ApiService.get(`/api/news/author/detail/${this.$route.params.slug}`)
        if (response && response.data && response.data.data) {
          this.author = response.data.data
        }
      }
    }
  },
}
</script>
