<template :key="key">
  <div class="vb-pagination">
    <nav aria-label="Page navigation">
      <ul class="pagination pagination-lg justify-content-center" v-if="getPageList()" >
        <li v-for="(item, i) in getPageList()" :key="i" class="page-item" :class="{active : (page == item.page || (item.page == 1 && !page))}">
          <span v-if="page == item.page" class="page-link" href="javascript:;">{{item.page}}</span>
          <template v-else>
            <custom-link v-if="item.page == 1" :to="{ name: this.routeName}" class="page-link" :title="item.title" v-html="item.html"></custom-link>
            <custom-link v-else :to="{ name: this.routeNamePage, params: { page: item.page } }" class="page-link" :title="item.title" v-html="item.html"></custom-link>
          </template>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>

export default {
  name: "PagingBlock",
  props: [
    'currentPage',
    'limit',
    'routeName',
    'routeNamePage',
    'total',
  ],

  data(){
    return{
      key: this.$route.name + '_' + this.$route.params.page,
      page: parseInt(this.$route.params.page),
    }
  },

  mounted(){

  },
  watch:{
    $route(route_to){
      this.page = route_to.params.page
    },
  },
  methods: {
    getPageList() {
      if(this.total){
        let range    = 6;
        let min      = 0;
        let max      = 0;
        let middle   = Math.ceil(range / 2);
        let max_page = Math.ceil(this.total / this.limit);

        let page = parseInt(this.$route.params.page) || 1;
        if (max_page < range) {
          min = 1;
          max = max_page;
        } else {
          min = page - middle + 1;
          max = page + middle - 1;
          if (min < 1) {
            min = 1;
            max = range;
          } else if (max > max_page) {
            max = max_page;
            min = max_page - range + 1;
          }
        }

        const pageList = [];
        /*if ($page != 1) {
          $pageList[] = '<li><a href="' . $link . '" title="trang Ä‘áº§u">First</a></li>';
        }*/

        if (page > 1) {
          pageList.push({
            page: page - 1,
            title: 'Trang trước',
            html: '<span aria-hidden="true">«</span><span class="sr-only">Trang trước</span>',
          })
        }

        for (let i = min; i <= max; i++) {
          pageList.push({
            page: i,
            title: 'Trang ' + i,
            html: i,
          })
        }

        if ((page + 1) <= max_page) {
          pageList.push({
            page: page + 1,
            title: 'Trang tiếp',
            html: '<span aria-hidden="true">»</span><span class="sr-only">Trang tiếp</span>',
          })
        }

        return pageList;
      }
      return null
    },
  },

};
</script>