<template>
  <AdsRow1></AdsRow1>
  <div id="main-wrapper">
    <div class="container">
      <FixtureList></FixtureList>
    </div>
  </div>
  <AdsRow4></AdsRow4>
  <AboutBlock></AboutBlock>
</template>
<script>
import FixtureList from "@/components/fixture/FixtureList";
import AboutBlock from "@/components/block/AboutBlock";
import AdsRow1 from "@/components/ads/AdsRow1";
import AdsRow4 from "@/components/ads/AdsRow4";
export default {
  name: "FixturePage",
  components: {
    FixtureList,
    AboutBlock,
    AdsRow1,
    AdsRow4,
  },
}
</script>