<template>
  <div class="vb-countdown">
    <div id="show-countdown">
      <div class="sc-title">
        <div v-if="!isStart">Trận đấu này sẽ bắt đầu sau</div>
        <div v-else>Trận đấu đang diễn ra</div>
      </div>
      <div class="sc-content" v-if="!isStart">
        <div v-show="days > 0"><div id="day">{{days}}</div><span>Ngày</span></div>
        <div><div id="hour">{{hours}}</div><span>Giờ</span></div>
        <div><div id="minute">{{minutes}}</div><span>Phút</span></div>
        <div v-show="days <= 0"><div id="second">{{seconds}}</div><span>Giây</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: "MatchCountDown",
  props:[
      'timestamp'
  ],
  setup(props){
    const days =ref(0)
    const hours =ref(0)
    const minutes =ref(0)
    const seconds =ref(0)
    const isStart =ref(false)
    function countTime() {
      const now = new Date().getTime();
      const t = props.timestamp - now;
      if(t <=0){
        isStart.value = true
      }
      days.value = Math.floor(t / (1000 * 60 * 60 * 24));
      hours.value = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
      minutes.value = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      seconds.value = Math.floor((t % (1000 * 60)) / 1000);
    }
    countTime()
    setInterval(function () {
      countTime()
    }, 1000)
    return{
      days, hours, minutes, seconds, isStart
    }

  }
}
</script>

<style scoped>

</style>