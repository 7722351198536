<template>
  <div id="g_id_onload" data-client_id="615275985305-r4em17j6evfj7g6gnf0342qrs0sdqvbk.apps.googleusercontent.com" data-context="signin" data-ux_mode="popup" data-callback="handleGoogleLogin" data-auto_prompt="false"></div>
  <div class="modal fade vbmodal vbmodal-login" id="modallogin" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="xclose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></div>
        <div class="tab-content">
          <div id="modal-tab-login" class="tab-pane show active">
            <div class="modal-body">
              <div class="text-left">
                <h5 class="modal-title">Chào huynh đài,</h5>
                <div class="description">Huynh đài cần đăng nhập để tham gia bình luận và sử dụng các tính năng nâng cao nhé.</div>
              </div>
                <div class="login-btn w-2btn">
                  <div id="google-btn" class="google-btn" @click="clickLogin">
                    <div class="google-icon-wrapper">
                      <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg">
                    </div>
                    <p class="btn-text"><b>Sign in with google</b></p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              <!--<form id="form-login" class="vbform" @submit="submit">
                <div class="login-btn w-2btn">
                  <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="filled_blue" data-text="signin_with" data-size="large" data-logo_alignment="left"></div>
                  <div class="clearfix"></div>
                </div>
              </form>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import * as $ from 'jquery'
import {ref} from "vue";
import UserService, {AUTH_DOMAIN} from "@/services/UserService";

export default {
  name: "LoginModal",
  data(){
    return {
      email: '',
      password: '',
      error_message: null,
    }
  },
  setup(){
    const loading = ref(false)
    return {
      loading
    }
  },
  methods:{
    async submit(e){
      e.preventDefault();
      this.error_message = null
      /*console.log({
        email: this.email,
        password: this.password,
      })*/
      this.loading = true
      try {
        const {
                // eslint-disable-next-line no-unused-vars
          user, access_token
        } = await UserService.login(this.email, this.password)
        if(user){
          try {
            $('#modallogin').modal('hide');
          }catch (e) {/**/}
          this.$store.dispatch('auth/login', {
            user,
          })
        }
        /*console.log('login-result', {
          user, access_token
        })*/
        return true
      }catch (e) {
        this.error_message = e.message
      }finally {
        this.loading = false
      }
      return false

    },
    clickRegister(){
      // window.location.href = `https://auth.vebotv.me/dang-ky?callback=${encodeURIComponent(window.location.href)}`;
      window.location.href = `${AUTH_DOMAIN}/dang-ky?callback=${encodeURIComponent(window.location.href)}`;
    },
    clickLogin() {
      window.location.href = `${AUTH_DOMAIN}/check-login?callback=${encodeURIComponent(window.location.origin + window.location.pathname)}`;
    }
  }
}
</script>

<style scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}
</style>
