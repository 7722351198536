<template>
  <div id="trending">
    <div class="container">
      <div class="box box-grid mb-0">
        <div class="box-header w-social">
          <h2 class="headlineA1">Trận Cầu Tâm Điểm</h2>
<!--          <div class="social-block">
            <div class="sb-text">Chia sẻ ngay</div>
            <div class="addthis_inline_share_toolbox"></div>
            <div class="clearfix"></div>
          </div>-->
          <div class="clearfix"></div>
        </div>
        <div class="box-content">
          <div class="xitems xitems-grid" v-if="featured">
            <MatchFeaturedItem v-for="item in featured" :key="item.id" :model-id="item.id"></MatchFeaturedItem>
          </div>
          <MatchFeaturedPlaceHolder v-else></MatchFeaturedPlaceHolder>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MatchFeaturedItem from "@/components/home/featured/MatchFeaturedItem";
import MatchFeaturedPlaceHolder from "@/components/loading/MatchFeaturedPlaceHolder";

export default {
  name: "MatchFeaturedList",
  components: {
    MatchFeaturedItem,
    MatchFeaturedPlaceHolder
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    featured : state => state.match.featured,
    matches : state => state.match.matches,
  }),
  mounted() {
    if(!this.$store.state.featured || this.$store.state.featured.length === 0){
      this.$store.dispatch('match/fetchFeatured')
    }
    setInterval(()=>{
      this.$store.dispatch('match/fetchFeatured')
    }, 2 * 60 * 1000)
  },
  methods:{
  }
}
</script>

<style scoped>

</style>