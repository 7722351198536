<template>
  <div class="box box-fixtures-max mb-0">
    <div class="box-header">
      <h1 class="headlineA1">KẾT QUẢ BÓNG ĐÁ {{today}} MỚI NHẤT</h1>
    </div>
    <div class="description des-page box-wpd bg-vebo" id="content_top" style="margin:8px 0;">Kết quả bóng đá mới nhất sẽ được cập nhật liên tục 24h</div>
    <div class="box-content box-wbg">
      <div class="match_date">
        <div class="md_-list">
          <custom-link :to="{ name: 'result_day', params: { slug: date.slug } }"
                       class="item" :class="{active : date.value === active_date}"
                       v-for="date in dates" :key="date.value"
                       @click="()=>{ this.active_date = date.value}">
            {{ date.day }}
          </custom-link>
        </div>
        <div class="clearfix"></div>
      </div>
      <div id="date-overlay"></div>

      <div v-for="date in dates" :key="date.value" v-show="date.value === active_date">
        <ResultDate :date="date.value"></ResultDate>
      </div>
      <div id="content_bot" style="text-align: center; margin: 10px 0 30px"></div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import * as moment from "moment";
import * as _ from "lodash";
import ResultDate from "@/components/result/ResultDate";
import {mapState} from "vuex";
import {useRoute} from "vue-router/dist/vue-router";

export default {
  name      : "ResultPage",
  components: {
    ResultDate
  },
  setup() {
    const route    = useRoute();
    let init_dates = []
    for (let i = 0; i > -4; i--) {
      const dateTime = moment().add(i, 'days')
      let day        = dateTime.format('DD/MM')
      let slug       = 'ngay-' + dateTime.format('DD-MM-YYYY')
      const value    = dateTime.format('YYYYMMDD')
      if (i === 0) {
        day  = 'Hôm nay'
        slug = 'hom-nay'
      }
      init_dates.push({
        day  : day,
        slug : slug,
        value: value,
        time : dateTime
      })
    }

    if (route.params.slug && route.params.slug !== 'hom-nay') {
      const parsed = moment(route.params.slug.replace('ngay-', ''), 'DD-MM-YYYY')
      if (parsed.isValid()) {
        const index = _.findIndex(init_dates, {'value': parsed.format('YYYYMMDD')});
        if (index === -1) {
          init_dates.push({
            day  : parsed.format('DD/MM'),
            slug : route.params.slug,
            value: parsed.format('YYYYMMDD'),
            time : parsed
          })
        }
      }
    }
    init_dates  = _.orderBy(init_dates, ['value'], ['asc']);
    const dates = ref(init_dates)
    return {
      dates,
      slug: route.params.slug,
    }
  },
  data() {
    let active_date = this.slug || null;
    if (!active_date || ['hom-nay', 'ngay-' + moment().format('DD-MM-YYYY')].includes(active_date)) {
      active_date = null
    } else {
      active_date = active_date.replace('ngay-', '');
      active_date = moment(active_date, 'DD-MM-YYYY').format('YYYYMMDD')
    }

    return {
      active_date: active_date,
      today      : moment().format('DD/MM')
    }
  },
  watch   : {
    // whenever question changes, this function will run
    active_date(value, old) {
      if (value !== 'live' && value !== old) {
        if (!this.date_matches[value]) {
          const store = this.$store
          setTimeout(function () {
            store.commit('match/setDateFixture', {
              date: value,
              data: null
            })
            store.dispatch('match/fetchDateFixtures', value)
          }, 0)
        }
      }
    }
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      live        : state => state.match.live,
      date_matches: state => state.match.date_matches,
    }),
  },
  async mounted() {
    if (this.active_date == null) {
      this.active_date = moment().format('YYYYMMDD')
    } else {
      this.$store.dispatch('match/fetchDateFixtures', this.active_date)
    }
    await this.getMeta('result',this.active_date);
  }
}
</script>