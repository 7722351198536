import { createApp } from 'vue'
import App from './App.vue'
import ApiService from "@/services/ApiService";
import store from "./store";
//import VueLazyLoad from 'vue3-lazyload'
import router from "@/router";
import CustomLink from "@/libs/custom-link";
// eslint-disable-next-line no-unused-vars
//import * as $ from 'jquery'
//window.$ = jquery
//import moment from "moment";

/*require('@/../public/bk/static/stylesheet/wrapper.css')
require('@/../public/bk/static/stylesheet/mobile.css')
require('@/../public/bk/static/stylesheet/custom.css')*/

function makeid(length) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const app = createApp(App)

//banhkhuc
// app.config.globalProperties.$themeId       = 'banhkhuc'
// app.config.globalProperties.$siteName      = 'Bánh Khúc TV'
// app.config.globalProperties.$domainName    = 'BanhKhucTV'
// app.config.globalProperties.$logo          = '/bk/static/img/logo.png'
// app.config.globalProperties.$banner        = '/bk/static/img/icon-min.webp'
// app.config.globalProperties.$ads_color_top = 'rgb(70 108 24)'

//vebotv
// app.config.globalProperties.$themeId       = 'vebotv'
// app.config.globalProperties.$siteName      = 'Về Bờ TV'
// app.config.globalProperties.$domainName    = 'VeBoTV'
// app.config.globalProperties.$logo          = '/vb/static/img/logo.png'
// app.config.globalProperties.$banner        = '/vb/static/img/icon-min.webp'
// app.config.globalProperties.$ads_color_top = '#111'

app.config.globalProperties.$siteId        = window.siteId
app.config.globalProperties.$themeId       = window.themeId
app.config.globalProperties.$siteName      = window.siteName
app.config.globalProperties.$domainName    = window.domainName
app.config.globalProperties.$logo          = window.logo
app.config.globalProperties.$banner        = window.banner
app.config.globalProperties.$ads_color_top = window.ads_color_top

app.config.globalProperties.$adsClass   = '_' + makeid(6)
app.config.globalProperties.$modalClass = '_' + makeid(4)

ApiService.init(app);
app.use(store);
app.use(router)
app.use(CustomLink)
//app.use(VueLazyLoad);

app.mixin({
	methods: {
		getBtnAdsBet(){
			let bet_btn = null
			if (this.$route.name === 'match') {
				if(this.$store.state.asd.live){
					bet_btn = this.$store.state.asd.live.btn
				}
			}else{
				if(this.$store.state.asd.home){
					bet_btn = this.$store.state.asd.home.btn
				}
			}
			if(bet_btn && bet_btn.bet){
				return '/goto?url=' + encodeURIComponent(bet_btn.bet)
			}
			return '#'
		},
		getBtnAdsBetHome(){
			let bet_btn = null
			if(this.$store.state.asd.home){
				bet_btn = this.$store.state.asd.home.btn
			}
			if(bet_btn && bet_btn.home_match){
				return '/goto?url=' + encodeURIComponent(bet_btn.home_match)
			}
			return '#'
		},
		getBtnAdsBetLive(){
			let bet_btn = null
			if(this.$store.state.asd.live){
				bet_btn = this.$store.state.asd.live.btn
			}
			if(bet_btn && bet_btn.live_match){
				return '/goto?url=' + encodeURIComponent(bet_btn.live_match)
			}
			return '#'
		},
		getMeta: async function (route_name, param = null) {
			let url = `/api/meta/${window.location.hostname}/${route_name}`;
			if(param){
				url += `/${param}`;
			}
			let data_res = await ApiService.get(url);

			let refreshInterval = setInterval(() => {
				if(data_res && data_res.data && data_res.data.data){
					let data = data_res.data.data;
					document.title = data.title;
					if (document.querySelector('meta[name="description"]') && data.description) {
						document.querySelector('meta[name="description"]').setAttribute("content", data.description);
					}
					if (document.querySelector('meta[name="keywords"]') && data.keywords) {
						document.querySelector('meta[name="keywords"]').setAttribute("content", data.keywords);
					}
					if (document.querySelector('meta[property="og:title"]') && data.title) {
						document.querySelector('meta[property="og:title"]').setAttribute("content", data.title);
					}
					if (document.querySelector('meta[property="og:description"]') && data.description) {
						document.querySelector('meta[property="og:description"]').setAttribute("content", data.description);
					}
					if (document.querySelector('meta[property="og:image"]') && data.og_image) {
						document.querySelector('meta[property="og:image"]').setAttribute("content", data.og_image);
					}
					if (document.querySelector('h1') && data.h1) {
						document.querySelector('h1').innerHTML = data.h1;
					}
					if (document.querySelector('#content_top') && data.content_top) {
						document.querySelector('#content_top').innerHTML = data.content_top;
					}
					if (document.querySelector('#content_bot') && data.content_bot) {
						document.querySelector('#content_bot').innerHTML = data.content_bot;
					}
				}
			}, 500);
			setTimeout(function( ) { clearInterval(refreshInterval); }, 10000);
		},
	},
})

app.mount('#app-web')

