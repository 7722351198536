
export const MATCH_STATUS = {
    PENDING: 'pending',
    LIVE: 'live',
    FINISHED: 'finished',
    CANCELED: 'canceled',
    POSTPONED: 'postponed',
    INTERRUPTED: 'interrupted',
    DELAY: 'delay',
}
export default class Tournament {
    name
    slug
    logo
    is_featured
    priority
    //status
    cur_season_id
    cur_round
    round_count
    primary_color
    secondary_color
    country
    region
    id
    tier
    countryPriority

    constructor(obj) {
        Object.assign(this, obj)
    }
    get location(){
        return (this.country && this.country.name) ? this.country.name : this.region.region_name
        //return this.region
    }

}

