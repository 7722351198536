<template>
  <div v-if="fixture()" >
    <TournamentResult v-for="(item, index) in fixture()"
                       :key="index"
                       :tournament="item.tournament"
                       :fixture="item.matches"
    ></TournamentResult>
  </div>
  <FixtureLoading v-else></FixtureLoading>
</template>

<script>
import TournamentResult from "@/components/result/TournamentResult";
import FixtureLoading from "@/components/loading/FixtureLoading";
import {mapState} from "vuex";
import {group_by_tournament} from "@/func/match.func";

export default {
  name: "FixtureDate",
  components: {
    TournamentResult,
    FixtureLoading,
  },
  props: [
      'date'
  ],
  data(){
    return {
      page: 1
    }
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      live: state => state.match.live,
      date_matches: state => state.match.date_matches,
    }),
  },
  methods:{
    fixture() {
      let data = null
      if(this.date === 'live'){
        data =  group_by_tournament(this.live)
      }else {
        data = this.date_matches[this.date]
      }
      if(data && data.length > 0){
        return data
        //return data.slice(0, (this.page) * 10)
      }
      return null
    },
  }
}
</script>

<style scoped>

</style>