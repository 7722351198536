import axios from "axios";
import VueAxios from "vue-axios";
import UserService, {TOKEN_KEY} from "@/services/UserService";

export default class ApiService {
    /**
     * @description property to share vue instance
     */
    static vueInstance;

    static init(app) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL = "https://api.vebo.xyz";
        // ApiService.vueInstance.axios.defaults.baseURL = "http://localhost:91";
        ApiService.vueInstance.axios.defaults.withCredentials = true;
        this.setHeader()
    }

    /**
     * @description set the default HTTP request headers
     */

    static getCookie(t){for(var e=t+"=",n=document.cookie.split(";"),i=0;i<n.length;i++){for(var r=n[i];" "==r.charAt(0);)r=r.substring(1);if(0==r.indexOf(e))return r.substring(e.length,r.length)}return""}

    static setHeader() {
        const token = UserService.getToken() || this.getCookie(TOKEN_KEY)
        if(token){
            ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }else {
            delete ApiService.vueInstance.axios.defaults.headers.common["Authorization"];
        }

        // ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
        //     "application/json";
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static query(
        resource,
        params
    ) {
        return ApiService.vueInstance.axios.get(resource, params);
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    static get(
        resource
    ) {
        return ApiService.vueInstance.axios.get(`${resource}`);
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    static post(
        resource,
        params
    ){
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

}
