import ApiService from "@/services/ApiService";
import Match from "@/store/models/match";
import {group_by_date} from "@/func/match.func";
import Tournament from "@/store/models/tournament";

export default {
    namespaced: true,
    state: {
        detail: null,
        featured: null,
        live: null,
        results: [],
        fixtures: [],
        menu: [],
        //matches: {},
    },
    actions: {
        async fetchMenu({commit}) {
            try {
                const response = await ApiService.get(`/api/tournament/menu`)
                const data = response.data.data
                Promise.resolve(commit('setMenu', data))
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

        async fetchDetail({commit}, tournamentId) {
            try {
                const response = await ApiService.get(`/api/tournament/${tournamentId}`)

                const data = response.data.data
                Promise.resolve(commit('setDetail', new Tournament(data)))

            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

        async fetchFeatured({commit}, tournamentId) {
            try {
                const data = await ApiService.get(`/api/tournament/featured/${tournamentId}`)
                const matches = data.data.data.map(item => {
                    Promise.resolve(commit('match/addMatch', item, { root: true}))
                    return new Match(item)
                })
                commit('setFeatured', matches)
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

        async fetchDateResult({commit}, tournamentId) {
            try {
                const data = await ApiService.get(`/api/tournament/result/${tournamentId}`)

                let matches         = data.data.data.map(item => {
                    Promise.resolve(commit('match/addMatch', item, { root: true}))
                    return new Match(item)
                })
                commit('setDateResults', group_by_date(matches))
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

        async fetchDateFixture({commit}, tournamentId) {
            try {
                const data = await ApiService.get(`/api/tournament/fixture/${tournamentId}`)

                let matches         = data.data.data.map(item => {
                    Promise.resolve(commit('match/addMatch', item, { root: true}))
                    return new Match(item)
                })
                commit('setDateFixtures', group_by_date(matches, 'asc'))
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },
    },
    mutations: {
        setDateResults(state, data) {
            state.results = data
        },
        setDateFixtures(state, data) {
            state.fixtures = data
        },
        /*addMatch(state, match) {
            let newObj = new Match(match)
            let oldObj = state.matches['id_' + match.id]
            if (oldObj) {
                if (newObj.match_status === MATCH_STATUS.PENDING || (newObj.match_status === MATCH_STATUS.LIVE && !newObj.time_str)) {
                    //
                    newObj.time_str = oldObj.time_str
                    if ([
                        MATCH_STATUS.CANCELED,
                        MATCH_STATUS.DELAY,
                        MATCH_STATUS.POSTPONED,
                        MATCH_STATUS.INTERRUPTED].indexOf(oldObj.match_status) < 0)
                    {
                        newObj.match_status = oldObj.match_status
                    }
                }
            }
            state.matches['id_' + match.id] = newObj
        },*/

        setFeatured(state, data) {
            state.featured = data
        },

        setLive(state, data) {
            state.live = data
        },

        setDetail(state, data) {
            state.detail = data
        },

        setMenu(state, data) {
            state.menu = data
        }
    },
    getters: {},
}