<template>
  <div id="about">
    <div class="container">
      <div v-if="data" v-html="data"></div>
      <div v-else  class="box box-about">
        <div class="box-header">
          <h2 class="headlineA1">Lời Giới Thiệu</h2>
        </div>
        <div class="box-content">
          <div class="about-wrap">
            <div id="vb-faq" class="aw_-menu">
              <div class="awm-title">Nội dung chính</div>
              <div class="box-ul">
                <ul id="vb-faq-ul" class="no-bullets">
                  <li><a href="#about-01" title=""><span>01</span>{{this.$siteName}} là gì? </a></li>
                  <li><a href="#about-02" title=""><span>02</span>Mục tiêu phát triển của trang trực tiếp bóng đá {{this.$siteName}} </a></li>
                  <li><a href="#about-03" title=""><span>03</span>Địa chỉ xem bóng đá trực tuyến được cộng đồng mạng yêu thích nhất – {{this.$siteName}} </a></li>
                  <li><a href="#about-04" title=""><span>04</span>Kênh trực tiếp bóng đá {{this.$domainName}} có gì đặc sắc?</a></li>
                  <li><a href="#about-05" title=""><span>05</span>Giao diện website {{this.$siteName}}</a></li>
                  <li><a href="#about-06" title=""><span>06</span>Những giải đấu bóng đá được phát trực tiếp trên {{this.$domainName}}</a></li>
                  <li><a href="#about-07" title=""><span>07</span>Hướng dẫn cách xem trực tiếp bóng đá tại {{this.$domainName}}</a></li>
                  <li><a href="#about-08" title=""><span>08</span>Chất lượng hình ảnh khi xem bóng đá trực tuyến tại {{this.$siteName}}</a></li>
                  <li><a href="#about-09" title=""><span>09</span>Cung cấp link xem trực tiếp bóng đá Euro 2021 nhanh nhất</a></li>
                  <li><a href="#about-10" title=""><span>10</span>Vì sao nên lựa chọn {{this.$domainName}} để xem trực tiếp bóng đá?</a></li>
                  <li><a href="#about-11" title=""><span>11</span>Tìm kiếm trận đấu bóng đá đang phát dễ dàng, nhanh chóng</a></li>
                  <li><a href="#about-13" title=""><span>12</span>Một vài lưu ý nhỏ khi xem trực tiếp bóng đá {{this.$siteName}}</a></li>
                </ul>
              </div>
            </div>
            <div class="aw_-content">
              <div id="about-01">
                <h2 class="headlineA1 highlight"><strong>{{this.$siteName}} là gì?</strong></h2>
                <p>{{this.$siteName}} là địa chỉ phát <a href="/">trực tiếp bóng đá miễn phí</a> được cộng động mạng yêu thích nhất hiện nay, {{this.$siteName}} phát sóng tất cả các giải bóng đá cấp quốc gia, thế giới, và trận đấu nổi tiếng ở nhiều khu vực, châu lục. Người truy cập sẽ được xem các video <a href="/">trực tiếp bóng đá chất lượng cao</a>, truyền hình trực tiếp hoặc xem lại trận đấu, nghe bình luận tiếng Việt rõ ràng chuyên nghiệp. Hình ảnh sắc nét, âm thanh sống động, và hỗ trợ đường truyền internet ổn định. Mọi trận đấu đều được phát sóng hoàn toàn miễn phí cho tất cả khán giả theo dõi.</p>
                <p>{{this.$domainName}} là một trong những chuyên trang trực tiếp bóng đá chất lượng nhất hiện nay có thể đáp ứng được nhu cầu giải trí của đông đảo người hâm mộ. Mọi trận đấu đều được phát sóng hoàn toàn miễn phí tại XoiLacTV, vì thế các bạn đừng quên truy cập vào đây thường xuyên để cập nhật những link xem bóng mới nhất nhé. </p>
                <p>&nbsp;</p>
              </div>
              <div id="about-02">
                <h2 class="headlineA1 highlight"><strong>Mục tiêu phát triển của trang trực tiếp bóng đá {{this.$siteName}}</strong></h2>
                <p>Để có thể xem trực tiếp bóng đá các trận đấu bóng đá mình yêu thích một cách dễ dàng nhất, các bạn cần phải lựa chọn được một địa chỉ uy tín và chất lượng. Đặc biệt các kênh xem bóng trực tuyến miễn phí sẽ luôn là lựa chọn được ưu tiên hàng đầu của mọi người. Hiểu được tâm lý chung này của người hâm mộ tại Việt Nam cũng như trên thế giới, một đội ngũ chuyên gia bóng đá đã nghiên cứu và xây dựng lên trang web {{this.$domainName}} nhằm mục đích giúp người hâm mộ thỏa mãn đam mê của mình.</p>
                <p>Với phương châm hoạt động “phục vụ làm hài lòng tất cả fan hâm mộ bóng đá”, Tructiepbongda {{this.$domainName}} luôn đặt quyền lợi của người dùng lên hàng đầu và sẵn sàng cải thiện, nâng cấp cơ sở hạ tầng để mang lại các sản phẩm và dịch vụ tốt nhất phục vụ khán giả. Đáng chú ý nhất là {{this.$siteName}} không thu bất cứ khoản phí nào của quý vị, mọi thao tác truy cập và tìm kiếm thông tin, xem trực tiếp bóng đá tại trang web này đều được miễn phí 100%. Đây cũng chính là lý do giúp {{this.$domainName}} ngày càng được nhiều người biết đến và tin tưởng sử dụng.</p>
                <p>Đến với {{this.$siteName}} các bạn sẽ được cập nhật nhanh chóng và đầy đủ hệ thống link xem trực tiếp bóng đá của các giải đấu hàng đầu hiện nay như: Ngoại hạng Anh, Bóng đá Ý, Bóng đá Đức, Bóng đá Tây Ban Nha, World Cup. Euro. Hệ thống link trực tiếp luôn được đảm bảo có chất lượng tốt nhất: Hình ảnh sắc nét, âm thanh rõ ràng, có hỗ trợ bình luận tiếng Việt, đường truyền ổn định, mượt mà.</p>
                <p>&nbsp;</p>
              </div>
              <div id="about-03">
                <h2 class="headlineA1 highlight"><strong>Địa chỉ xem bóng đá trực tuyến được cộng đồng mạng yêu thích nhất – {{this.$siteName}}</strong></h2>
                <p>Nếu bạn là một người yêu thích tìm hiểu tin tức bóng đá, nắm bắt lịch thi đấu, thứ hạng các đội hoặc kết quả các trận đấu đã được tổ chức cũng có thể lựa chọn {{this.$domainName}}… Các thông tin này sẽ được chúng tôi cập nhật liên tục theo từng giờ, từng ngày nên các bạn không lo bị bỏ lỡ các thông tin mới nhất.</p>
                <p>Với mục tiêu phát triển ngày càng mạnh mẽ và trở thành chuyên trang trực tiếp bóng đá online miễn phí hàng đầu tại Việt Nam, {{this.$domainName}} luôn ưu tiên chất lượng lên hàng đầu, các thông tin được chúng tôi cung cấp đều được lấy từ các nguồn tin uy tín, sau đó tiến hành đánh giá, phân tích chi tiết để gửi đến người dùng những thông tin có độ chính xác cao nhất.</p>
                <p>&nbsp;</p>
              </div>
              <div id="about-04">
                <h2 class="headlineA1 highlight"><strong>Kênh trực tiếp bóng đá {{this.$domainName}} có gì đặc sắc?</strong></h2>
                <p>Chắc chắn các bạn sẽ cảm thấy bất ngờ khi truy cập vào trang web tructiepbongda {{this.$domainName}}, bởi tại đây không chỉ cung cấp hệ thống link xem trực tiếp bóng đá của tất cả các giải đấu bóng đá lớn nhỏ trên thế giới từ Ngoại hạng Anh, Champions League, La Liga, World Cup, bóng đá Tây Ban Nha cho tới V-League, AFF Cup, Sea Games….mà còn chia sẻ rất nhiều thông tin hữu ích khác có liên quan đến bóng đá Việt Nam và thế giới.</p>
                <p>Với những ai yêu thích các trận cầu đỉnh cao của các CLB lớn trên thế giới như Manchester United, Arsenal, Barca, Chelsea, Manchester City, Liverpool, Real Madrid… cũng có thể lấy link xem trực tiếp bóng đá hôm nay một cách dễ dàng và nhanh chóng tại Xôi Lac TV. Bên cạnh đó các link xem bóng đá của các đội tuyển Đức, Ý, Tây Ban Nha, Pháp, Thái Lan, Việt Nam… cũng sẽ được đội ngũ chuyên gia của chúng tôi tổng hợp mới nhất trên trang.</p>
                <p>Các link trực tiếp bóng đá 2021 luôn đảm bảo có chất lượng tốt nhất nhờ trang thiết bị kỹ thuật và hệ thống server hiện tại, mang tới những thước video sắc nét, hình ảnh, âm thanh rõ ràng, không xảy ra tình trạng bị lag, giật trong quá trình theo dõi. Đặc biệt là các trận đấu đều được hỗ trợ bình luận bằng tiếng Việt giúp cho việc theo dõi được thuận lợi hơn.</p>
                <p>Ngoài ra, {{this.$siteName}} còn cập nhật đầy đủ và nhanh chóng tất cả các thông tin về lịch thi đấu, bảng xếp hạng, kết quả thi đấu, livescore, tỷ lệ kèo và tin tức bóng đá mỗi ngày để người dùng có cái nhìn khách quan nhất về bóng đá trong nước cũng như thế giới. Trong danh sách các trận đấu sắp diễn ra cũng hiển thị tất cả những thông tin có liên quan nhưng thời gian chính thức bắt đầu, đội tuyển đối đầu, vòng bảng và ở giải đấu nào,… vì thế mọi người sẽ không mất nhiều thời gian để tìm kiếm thông tin.</p>
                <p>&nbsp;</p>
              </div>
              <div id="about-05">
                <h2 class="headlineA1 highlight"><strong>{{this.$siteName}} là gì?</strong></h2>
                <p>{{this.$siteName}} là địa chỉ phát <a href="/">trực tiếp bóng đá miễn phí</a> được cộng động mạng yêu thích nhất hiện nay, {{this.$siteName}} phát sóng tất cả các giải bóng đá cấp quốc gia, thế giới, và trận đấu nổi tiếng ở nhiều khu vực, châu lục. Người truy cập sẽ được xem các video <a href="/">trực tiếp bóng đá chất lượng cao</a>, truyền hình trực tiếp hoặc xem lại trận đấu, nghe bình luận tiếng Việt rõ ràng chuyên nghiệp. Hình ảnh sắc nét, âm thanh sống động, và hỗ trợ đường truyền internet ổn định. Mọi trận đấu đều được phát sóng hoàn toàn miễn phí cho tất cả khán giả theo dõi.</p>
                <p>{{this.$domainName}} là một trong những chuyên trang trực tiếp bóng đá chất lượng nhất hiện nay có thể đáp ứng được nhu cầu giải trí của đông đảo người hâm mộ. Mọi trận đấu đều được phát sóng hoàn toàn miễn phí tại XoiLacTV, vì thế các bạn đừng quên truy cập vào đây thường xuyên để cập nhật những link xem bóng mới nhất nhé. </p>
                <p>&nbsp;</p>
              </div>
              <div id="about-06">
                <h2 class="headlineA1 highlight"><strong>Mục tiêu phát triển của trang trực tiếp bóng đá {{this.$siteName}}</strong></h2>
                <p>Để có thể xem trực tiếp bóng đá các trận đấu bóng đá mình yêu thích một cách dễ dàng nhất, các bạn cần phải lựa chọn được một địa chỉ uy tín và chất lượng. Đặc biệt các kênh xem bóng trực tuyến miễn phí sẽ luôn là lựa chọn được ưu tiên hàng đầu của mọi người. Hiểu được tâm lý chung này của người hâm mộ tại Việt Nam cũng như trên thế giới, một đội ngũ chuyên gia bóng đá đã nghiên cứu và xây dựng lên trang web {{this.$domainName}} nhằm mục đích giúp người hâm mộ thỏa mãn đam mê của mình.</p>
                <p>Với phương châm hoạt động “phục vụ làm hài lòng tất cả fan hâm mộ bóng đá”, Tructiepbongda {{this.$domainName}} luôn đặt quyền lợi của người dùng lên hàng đầu và sẵn sàng cải thiện, nâng cấp cơ sở hạ tầng để mang lại các sản phẩm và dịch vụ tốt nhất phục vụ khán giả. Đáng chú ý nhất là {{this.$siteName}} không thu bất cứ khoản phí nào của quý vị, mọi thao tác truy cập và tìm kiếm thông tin, xem trực tiếp bóng đá tại trang web này đều được miễn phí 100%. Đây cũng chính là lý do giúp {{this.$domainName}} ngày càng được nhiều người biết đến và tin tưởng sử dụng.</p>
                <p>Đến với {{this.$siteName}} các bạn sẽ được cập nhật nhanh chóng và đầy đủ hệ thống link xem trực tiếp bóng đá của các giải đấu hàng đầu hiện nay như: Ngoại hạng Anh, Bóng đá Ý, Bóng đá Đức, Bóng đá Tây Ban Nha, World Cup. Euro. Hệ thống link trực tiếp luôn được đảm bảo có chất lượng tốt nhất: Hình ảnh sắc nét, âm thanh rõ ràng, có hỗ trợ bình luận tiếng Việt, đường truyền ổn định, mượt mà.</p>
                <p>&nbsp;</p>
              </div>
              <div id="about-07">
                <h2 class="headlineA1 highlight"><strong>Địa chỉ xem bóng đá trực tuyến được cộng đồng mạng yêu thích nhất – {{this.$siteName}}</strong></h2>
                <p>Nếu bạn là một người yêu thích tìm hiểu tin tức bóng đá, nắm bắt lịch thi đấu, thứ hạng các đội hoặc kết quả các trận đấu đã được tổ chức cũng có thể lựa chọn {{this.$domainName}}… Các thông tin này sẽ được chúng tôi cập nhật liên tục theo từng giờ, từng ngày nên các bạn không lo bị bỏ lỡ các thông tin mới nhất.</p>
                <p>Với mục tiêu phát triển ngày càng mạnh mẽ và trở thành chuyên trang trực tiếp bóng đá online miễn phí hàng đầu tại Việt Nam, {{this.$domainName}} luôn ưu tiên chất lượng lên hàng đầu, các thông tin được chúng tôi cung cấp đều được lấy từ các nguồn tin uy tín, sau đó tiến hành đánh giá, phân tích chi tiết để gửi đến người dùng những thông tin có độ chính xác cao nhất.</p>
                <p>&nbsp;</p>
              </div>
              <div id="about-08">
                <h2 class="headlineA1 highlight"><strong>Kênh trực tiếp bóng đá {{this.$domainName}} có gì đặc sắc?</strong></h2>
                <p>Chắc chắn các bạn sẽ cảm thấy bất ngờ khi truy cập vào trang web tructiepbongda {{this.$domainName}}, bởi tại đây không chỉ cung cấp hệ thống link xem trực tiếp bóng đá của tất cả các giải đấu bóng đá lớn nhỏ trên thế giới từ Ngoại hạng Anh, Champions League, La Liga, World Cup, bóng đá Tây Ban Nha cho tới V-League, AFF Cup, Sea Games….mà còn chia sẻ rất nhiều thông tin hữu ích khác có liên quan đến bóng đá Việt Nam và thế giới.</p>
                <p>Với những ai yêu thích các trận cầu đỉnh cao của các CLB lớn trên thế giới như Manchester United, Arsenal, Barca, Chelsea, Manchester City, Liverpool, Real Madrid… cũng có thể lấy link xem trực tiếp bóng đá hôm nay một cách dễ dàng và nhanh chóng tại Xôi Lac TV. Bên cạnh đó các link xem bóng đá của các đội tuyển Đức, Ý, Tây Ban Nha, Pháp, Thái Lan, Việt Nam… cũng sẽ được đội ngũ chuyên gia của chúng tôi tổng hợp mới nhất trên trang.</p>
                <p>Các link trực tiếp bóng đá 2021 luôn đảm bảo có chất lượng tốt nhất nhờ trang thiết bị kỹ thuật và hệ thống server hiện tại, mang tới những thước video sắc nét, hình ảnh, âm thanh rõ ràng, không xảy ra tình trạng bị lag, giật trong quá trình theo dõi. Đặc biệt là các trận đấu đều được hỗ trợ bình luận bằng tiếng Việt giúp cho việc theo dõi được thuận lợi hơn.</p>
                <p>Ngoài ra, {{this.$siteName}} còn cập nhật đầy đủ và nhanh chóng tất cả các thông tin về lịch thi đấu, bảng xếp hạng, kết quả thi đấu, livescore, tỷ lệ kèo và tin tức bóng đá mỗi ngày để người dùng có cái nhìn khách quan nhất về bóng đá trong nước cũng như thế giới. Trong danh sách các trận đấu sắp diễn ra cũng hiển thị tất cả những thông tin có liên quan nhưng thời gian chính thức bắt đầu, đội tuyển đối đầu, vòng bảng và ở giải đấu nào,… vì thế mọi người sẽ không mất nhiều thời gian để tìm kiếm thông tin.</p>
                <p>&nbsp;</p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "AboutBlock",
  data () {
    return {
      data: null
    }
  },
  async mounted() {
    const response = await ApiService.get(`/api/news/${this.$siteId}/about`)
    if(response && response.data && response.data.data){
      this.data = response.data.data
    }
  },
}
</script>
