import ApiService from "@/services/ApiService";
export default {
    namespaced: true,
    state: {
        home: null,
        live: null,
        bet_host: null,
    },
    actions: {
        async fetchHome({commit}, siteId) {
            try {
                const get = await ApiService.get(`/api/a/${siteId}/home`)
                if (get.data) {
                    commit('setHome', get.data)
                }

            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },
        async fetchLive({commit}, siteId) {
            try {
                const get = await ApiService.get(`/api/a/${siteId}/live`)
                if (get.data) {
                    commit('setLive', get.data)
                }
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },
        async fetchBetHost({commit}, siteId) {
            try {
                const get = await ApiService.get(`/api/a/${siteId}/bh`)
                if (get.data) {
                    commit('setBetHost', get.data)
                }
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

    },
    mutations: {
        setHome(state, data) {
            state.home = data
        },
        setLive(state, data) {
            state.live = data
        },
        setBetHost(state, data) {
            state.bet_host = data
        },
    },
    getters: {

    },
}