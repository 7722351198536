<template :key="route_name">
  <div v-if="ads && ads.row2" class="container vebo-sp">
    <div v-for="(line,i) in ads.row2" :key="i" :class="(line.class.replace('mo-',this.$adsClass+'-'))">
      <div v-for="(item,ii) in line.items" :key="ii" :class="this.$adsClass">
        <div :class="(item.class.replace('mmo-',this.$adsClass+'-'))">
          <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
            <img rel="nofollow" loading="lazy" :src="item.src"/></a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="container vebo-sp">
    <div class="mo-dual">
      <div class="mmo">
        <div class="mmo-inner size-728x90"></div>
      </div>
      <div class="mmo">
        <div class="mmo-inner size-728x90"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AdsRow2",
  data(){
    return {route_name: this.$route.name}
  },
  computed: {
    ads() {
      if(this.$route.name === 'match'){
        return this.$store.state.asd.live;
      }else{
        return this.$store.state.asd.home;
      }
    }
  },
  mounted() {
  }
}
</script>
