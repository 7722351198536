<template>
  <div id="main-wrapper" v-if="detail">
    <div class="container">
      <div class="box box-league">
        <div class="box-header">
          <h1 class="headlineA1" v-if="currentRouteName==='league_standing'">BXH {{ detail.name }} mới nhất</h1>
          <h1 class="headlineA1" v-else-if="currentRouteName==='league_result'">Kết quả {{ detail.name }} mới nhất</h1>
          <h1 class="headlineA1" v-else-if="currentRouteName==='league_schedule'">Lịch thi đấu {{ detail.name }} mới nhất</h1>
          <h1 class="headlineA1" v-else>Trực tiếp giải đấu {{ detail.name }}</h1>
          <div id="content_top" style="margin:8px 0;"></div>
        </div>
<!--        <div class="m-social w-social mb-3">
          <div class="social-block">
            <div class="sb-text">Chia sẻ giải đấu</div>
            <div class="addthis_inline_share_toolbox"></div>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix"></div>
        </div>-->
        <div class="com-detail">
          <div class="cd_-top">
            <div class="cover">
              <div class="cover-img" style="background-image: url('/images/cover-championleague.jpg')"></div>
            </div>
            <div class="content">
              <i class="com-icon"><img loading="lazy" :src="detail.logo"></i>
              <div class="info">
                <div class="country country_name">{{detail.location}}</div>
                <h2 class="com-name">{{ detail.name }}</h2>
                <!--<div class="description">20 đội <span class="dot"></span> Đá vòng tròn tính điểm</div>-->
              </div>
            </div>
          </div>
          <div class="cd_-menu w-social">
            <TournamentTabs :data="{route_current: currentRouteName, slug: this.slug, id: this.modelId}" ></TournamentTabs>
            <div class="social-block">
              <div class="sb-text">Chia sẻ giải đấu</div>
              <div class="addthis_inline_share_toolbox"></div>
              <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="cd_-main mw-w-sidebar is-basic">
            <div class="main-left">
              <TournamentOverview :model_id="modelId" v-if="currentRouteName==='league_overview'"></TournamentOverview>
              <TournamentStanding :model_id="modelId" v-if="currentRouteName==='league_standing'"></TournamentStanding>
              <TournamentResult :model_id="modelId" v-if="currentRouteName==='league_result'"></TournamentResult>
              <TournamentFixture :model_id="modelId" v-if="currentRouteName==='league_schedule'"></TournamentFixture>
            </div>
            <div class="sidebar-right">
              <BetHostSmall></BetHostSmall>
              <AdsTable1></AdsTable1>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div id="content_bot" style="text-align: center; margin: 10px 0 20px"></div>
    </div>
  </div>
</template>
<script>

// import {useRoute} from "vue-router/dist/vue-router";
import TournamentTabs from "@/components/tournament/TournamentTabs";
import TournamentOverview from "@/components/tournament/Overview";
import TournamentStanding from "@/components/tournament/Standing";
import TournamentResult from "@/components/tournament/Result";
import TournamentFixture from "@/components/tournament/Fixture";
import BetHostSmall from "@/components/ads/BetHostSmall";
import {mapState, useStore} from "vuex";
import AdsTable1 from "@/components/ads/AdsTable1.vue";

export default {
  name      : "TournamentPage",
  components: {
    AdsTable1,
    TournamentTabs,
    TournamentOverview,
    TournamentStanding,
    TournamentResult,
    TournamentFixture,
    BetHostSmall,
  },
  data() {
    return {
      modelId: this.$route.params.id,
      slug   : this.$route.params.slug
    }
  },
  watch: {
    $route(to) {
      this.modelId = to.params.id
      this.slug    = to.params.slug
    },
    modelId(value) {
      if (value) {
        this.$store.dispatch('tournament/fetchDetail', value);
      }
    }
  },
  setup() {

    const store = useStore();
    store.commit('tournament/setDetail', null);
    store.commit('tournament/setFeatured', null);
    store.commit('tournament/setDateResults', null);
    store.commit('tournament/setDateFixtures', null);
    // const route = useRoute();
    return {
      // slug   : route.params.slug,
      //modelId: '7AqMEiU',
    }
  },
  async mounted() {
    this.$store.dispatch('tournament/fetchDetail', this.modelId);
    await this.getMeta(this.$route.name, `${this.$route.params.id}`)
  },

  computed: {
    ...mapState({
      detail: state => state.tournament.detail,
    }),

    /*item() {
      return this.$store.state.match.detail;
    },*/

    currentRouteName() {
      return this.$route.name;
    },
  }
}
</script>
