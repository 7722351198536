<template>
  <div v-if="0" class="box box-table">
    <div class="box-header">
      <h2 class="headlineA1"><a href="table.html" title="">Bảng Xếp Hạng</a></h2>
    </div>
    <div class="box-content" v-if="1">
      <div class="table-list table-list-min">
        <div class="tl_-league">
          <div class="tl__-select" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="item-league"><i class="league-icon"><img src="/bk/images/thumbs/league-premier.png"></i><span>Premier League 2020-2021</span></div>
            <div class="select-league"><i class="fas fa-angle-down"></i></div>
            <div class="clearfix"></div>
          </div>
          <div class="dropdown-menu dropdown-menu-vb dropdown-menu-league">
            <a class="dropdown-item" href="#">
              <div class="item-league"><i class="league-icon"><img src="/bk/images/thumbs/league-champion.png"></i><span>UEFA Champions League</span></div>
            </a>
            <a class="dropdown-item active" href="#">
              <div class="item-league"><i class="league-icon"><img src="/bk/images/thumbs/league-premier.png"></i><span>Premier League</span></div>
            </a>
            <a class="dropdown-item" href="#">
              <div class="item-league"><i class="league-icon"><img src="/bk/images/thumbs/league-euro.png"></i><span>UEFA Europa League</span></div>
            </a>
            <a class="dropdown-item" href="#">
              <div class="item-league"><i class="league-icon"><img src="/bk/images/thumbs/league-laliga.png"></i><span>LaLiga</span></div>
            </a>
          </div>
        </div>
        <div class="tl_-allclub">
          <div class="tl-row tl_a-header">
            <div class="tld tld-number">#</div>
            <div class="tld tld-name">Đội bóng</div>
            <div class="tld tld-played">ST</div>
            <div class="tld tld-win">T</div>
            <div class="tld tld-draw">H</div>
            <div class="tld tld-lose">B</div>
            <div class="tld tld-gf">TG</div>
            <div class="tld tld-ga">TH</div>
            <div class="tld tld-gd">HS</div>
            <div class="tld tld-point">Đ</div>
            <div class="tld tld-result">KQ</div>
          </div>
          <div class="tl-row-wrap">
            <div class="tl-row">
              <div class="tld tld-number">1</div>
              <div class="tld tld-name w-pos pos-up"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-mancity.png"></div><strong>Manchester City</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">27</div>
              <div class="tld tld-draw">5</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">83</div>
              <div class="tld tld-ga">32</div>
              <div class="tld tld-gd">51</div>
              <div class="tld tld-point"><span class="score">86</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">2</div>
              <div class="tld tld-name w-pos pos-down"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-manutd.png"></div><strong>Manchester United</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">21</div>
              <div class="tld tld-draw">11</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">73</div>
              <div class="tld tld-ga">44</div>
              <div class="tld tld-gd">29</div>
              <div class="tld tld-point"><span class="score">74</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-draw"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">3</div>
              <div class="tld tld-name w-pos pos-stand"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-liverpool.png"></div><strong>Liverpool</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">20</div>
              <div class="tld tld-draw">9</div>
              <div class="tld tld-lose">9</div>
              <div class="tld tld-gf">68</div>
              <div class="tld tld-ga">42</div>
              <div class="tld tld-gd">26</div>
              <div class="tld tld-point"><span class="score">69</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">4</div>
              <div class="tld tld-name w-pos pos-up"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-chelsea.png"></div><strong>Chelsea</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">19</div>
              <div class="tld tld-draw">10</div>
              <div class="tld tld-lose">9</div>
              <div class="tld tld-gf">58</div>
              <div class="tld tld-ga">36</div>
              <div class="tld tld-gd">22</div>
              <div class="tld tld-point"><span class="score">67</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">5</div>
              <div class="tld tld-name w-pos pos-stand"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-leicester.png"></div><strong>Leicester City</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">27</div>
              <div class="tld tld-draw">5</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">83</div>
              <div class="tld tld-ga">32</div>
              <div class="tld tld-gd">51</div>
              <div class="tld tld-point"><span class="score">86</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">6</div>
              <div class="tld tld-name w-pos pos-up"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-westham.png"></div><strong>West Ham United</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">21</div>
              <div class="tld tld-draw">11</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">73</div>
              <div class="tld tld-ga">44</div>
              <div class="tld tld-gd">29</div>
              <div class="tld tld-point"><span class="score">74</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-draw"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">7</div>
              <div class="tld tld-name w-pos pos-down"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-tottenham.png"></div><strong>Tottenham Hotspur</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">20</div>
              <div class="tld tld-draw">9</div>
              <div class="tld tld-lose">9</div>
              <div class="tld tld-gf">68</div>
              <div class="tld tld-ga">42</div>
              <div class="tld tld-gd">26</div>
              <div class="tld tld-point"><span class="score">69</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">8</div>
              <div class="tld tld-name w-pos pos-down"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-ars.png"></div><strong>Arsenal</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">19</div>
              <div class="tld tld-draw">10</div>
              <div class="tld tld-lose">9</div>
              <div class="tld tld-gf">58</div>
              <div class="tld tld-ga">36</div>
              <div class="tld tld-gd">22</div>
              <div class="tld tld-point"><span class="score">67</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">9</div>
              <div class="tld tld-name w-pos pos-stand"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-leed.png"></div><strong>Leeds United</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">27</div>
              <div class="tld tld-draw">5</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">83</div>
              <div class="tld tld-ga">32</div>
              <div class="tld tld-gd">51</div>
              <div class="tld tld-point"><span class="score">86</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
            <div class="tl-row">
              <div class="tld tld-number">10</div>
              <div class="tld tld-name w-pos pos-up"><a href="club.html" title=""><div class="team-logo icon-20 mr-2"><img class="team-logo-img" src="/bk/images/thumbs/flag-leicester.png"></div><strong>Leicester City</strong></a></div>
              <div class="tld tld-played">38</div>
              <div class="tld tld-win">21</div>
              <div class="tld tld-draw">11</div>
              <div class="tld tld-lose">6</div>
              <div class="tld tld-gf">73</div>
              <div class="tld tld-ga">44</div>
              <div class="tld tld-gd">29</div>
              <div class="tld tld-point"><span class="score">74</span></div>
              <div class="tld tld-result">
                <span class="dot dot-win"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-lose"></span>
                <span class="dot dot-draw"></span>
                <span class="dot dot-win"></span>
              </div>
            </div>
          </div>
        </div>
        <a href="league-table.html" class="load-more"><i class="fas fa-angle-down mr-2"></i>Xem thêm</a>
      </div>
      <div class="clearfix"></div>
    </div>
    <StandingTableLoading v-else></StandingTableLoading>
  </div>
</template>

<script>
import StandingTableLoading from "@/components/loading/StandingTableLoading";
export default {
  name: "StandingTable",
  components: {
    StandingTableLoading
  }
}
</script>