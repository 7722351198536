<template :key="route_name">
  <div v-if="ads && ads.slide && ads.slide.left" :class="`${this.$adsClass} ${this.$adsClass}-fixside to-left`">
    <div :class="`${this.$adsClass}-inner size-160x800`">
      <a :href="'/goto?url=' + encodeURIComponent(ads.slide.left.href)" :target="ads.slide.left.target" rel="nofollow">
        <img rel="nofollow" loading="lazy" :src="ads.slide.left.src"></a>
    </div>
  </div>
  <div v-if="ads && ads.slide && ads.slide.right" :class="`${this.$adsClass} ${this.$adsClass}-fixside to-right`">
    <div :class="`${this.$adsClass}-inner size-160x800`">
      <a :href="'/goto?url=' + encodeURIComponent(ads.slide.right.href)" :target="ads.slide.right.target" rel="nofollow">
        <img rel="nofollow" loading="lazy" :src="ads.slide.right.src"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsSlide",
  data() {
    return {route_name: this.$route.name}
  },
  computed: {
    ads() {
      if (this.$route.name === 'match') {
        return this.$store.state.asd.live;
      } else {
        return this.$store.state.asd.home;
      }
    }
  },
  mounted() {
  }
}
</script>
