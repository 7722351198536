<template :key="route_name">
  <div class="box box-host">
    <div class="box-header" style="display: none">
      <h4 class="headlineA1"><a href="javascript:;" title="">Nhà Cái Uy Tín</a></h4>
    </div>
    <div class="box-content" v-if="data">
      <div class="host-list host-list-min">
        <div class="item" v-for="(item,i) in data" :key="i">
          <div class="host-logo">
            <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target"><img loading="lazy" :src="item.src" class="host-logo-img" /></a>
          </div>
          <h4 class="host-name">{{item.name}}</h4>
          <div class="description" v-html="item.content"></div>
          <div class="bet-now"><a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" class="btn btn-radius btn-primary btn-shadow">Cược ngay</a></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <BetHostLoading v-else></BetHostLoading>
  </div>
</template>
<script>
import BetHostLoading from "@/components/loading/BetHostLoading";
export default {
  name: "BetHostSmall",
  data(){
    return {route_name: this.$route.name}
  },
  components: {
    BetHostLoading
  },
  computed: {
    data() {
      return this.$store.state.asd.bet_host;
    }
  },
}
</script>