import ApiService from "@/services/ApiService";
import Match, {MATCH_STATUS} from "@/store/models/match";
import {group_by_tournament} from "@/func/match.func";
import * as _ from "lodash";

export default {
    namespaced: true,
    state: {
        detail: null,
        featured: null,
        live: null,
        date_matches: {},
        home_date_matches: {},
        matches: {},
        player: null,
        peerInstance: null
    },
    actions: {
        async fetchFeatured({commit}) {
            try {
                const data = await ApiService.get('/api/match/featured')
                const matches = data.data.data.map(item => {
                    Promise.resolve(commit('addMatch', item))
                    return new Match(item)
                })
                commit('setFeatured', matches)
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },

        async fetchDateFixtures({commit, state}, date) {
            if (state.date_matches[date]) {
                return
            }

            try {
                const data = await ApiService.get(`/api/match/fixture/${date}`)

                let matches = data.data.data.map(item => {
                    Promise.resolve(commit('addMatch', item))
                    return new Match(item)
                })
                commit('setDateFixture', {
                    date: date,
                    data: group_by_tournament(matches)
                })
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },
        async fetchHomeDateFixtures({commit, state}, date) {
            if (state.home_date_matches[date]) {
                return
            }

            try {
                const data = await ApiService.get(`/api/match/fixture/home/${date}`)

                let matches = data.data.data.map(item => {
                    Promise.resolve(commit('addMatch', item))
                    return new Match(item)
                })
                commit('setHomeDateFixture', {
                    date: date,
                    data: group_by_tournament(matches)
                })
            } catch (error) {
                //alert(error)
                console.log(error)
            }
        },
        async fetchLive({commit}) {
            try {
                const data = await ApiService.get('https://live.vebo.xyz/api/match/live')

                let matches = data.data.data.map(item => {
                    Promise.resolve(commit('addMatch', item))
                    return new Match(item)
                })
                matches = _.filter(matches, function (o) {
                    return o.isLive();
                })
                matches = _.orderBy(matches, ['timestamp'], ['asc']);
                commit('setLive', matches)
            } catch (error) {
                //alert(error)
                console.log(error)
            }

        },
        async fetchDetail({commit}, matchId) {
            try {
                const response = await ApiService.get(`/api/match/${matchId}`)

                const match = response.data.data
                Promise.resolve(commit('addMatch', match))
                Promise.resolve(commit('setDetail', new Match(match)))

            } catch (error) {
                //alert(error)
                console.log(error)
            }

        },

    },
    mutations: {
        setDateFixture(state, payload) {
            state.date_matches[payload.date] = payload.data
        },
        setHomeDateFixture(state, payload) {
            state.home_date_matches[payload.date] = payload.data
        },
        addMatch(state, match) {
            let newObj = new Match(match)
            let oldObj = state.matches['id_' + match.id]
            if (oldObj) {
                if (newObj.match_status === MATCH_STATUS.PENDING || (newObj.match_status === MATCH_STATUS.LIVE && !newObj.time_str)) {
                    //
                    newObj.time_str = oldObj.time_str
                    if ([
                        MATCH_STATUS.CANCELED,
                        MATCH_STATUS.DELAY,
                        MATCH_STATUS.POSTPONED,
                        MATCH_STATUS.INTERRUPTED].indexOf(oldObj.match_status) < 0)
                    {
                        newObj.match_status = oldObj.match_status
                    }
                }
            }
            state.matches['id_' + match.id] = newObj
        },

        setFeatured(state, data) {
            state.featured = data
        },

        setLive(state, data) {
            state.live = data
        },
        setDetail(state, data) {
            state.detail = data
        },
        async getMatch(state, match_id){
            return state.matches[match_id]
        },
        setPlayer(state, player){
            state.player = player
        },
        setPeerInstance(state, peerInstance){
            state.peerInstance = peerInstance
        }
    },
    getters: {

    },
}