<template>
    <div id="main-wrapper">
        <div class="container">
            <div v-if="deferredPrompt" class="text">
                <p>{{ callOut }}</p>
                <p class="mb-0">
                    <a @click="install" class="btn btn-radius btn-primary mr-1"><i class="fas fa-download mr-2"></i>Cài
                        đặt</a>
                </p>
            </div>
            <div v-if="!deferredPrompt" class="text">
                <p>App đã được cài đặt, bấm nút bên dưới để quay về trang chủ</p>
                <a href="/home" class="btn btn-radius btn-primary mr-1"><i class="fas fa-home mr-2"></i>Quay về trang chủ</a>
            </div>
        </div>
    </div>
</template>
  
<script>
import { getCurrentInstance } from "vue";

export default {
    name: "AppPage",
    setup() {
        const app = getCurrentInstance()
        const icon = app.appContext.config.globalProperties.$icon;
        const callOut = `Cài đặt ngay ứng dụng ${window.domainName} để theo dõi các trận đấu một cách nhanh nhất với chất lượng cao nhất.`
        return { icon, callOut }
    },
    data() {
        return {
            deferredPrompt: null,
        }
    },
    created() {
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            this.deferredPrompt = e;
        });
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null;
        });
    },
    methods: {
        async install() {
            this.deferredPrompt.prompt();
        }
    }
}
</script>