<template :key="route_name">
  <div v-if="data && data.footer && data.footer.pc" :class="`${this.$adsClass} ${this.$adsClass}-fixbot for-desktop ft-box`">
    <div style="max-width: 1330px;width: 100%!important;position: relative;margin: 0 auto">
      <div v-for="(item,i) in data.footer.pc" :key="i" :class="`${this.$adsClass}-inner ${item.class}`" >
        <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
          <img rel="nofollow" loading="lazy" :src="item.src"/></a>
      </div>
      <div class="rn-close remove-pr"><i class="fas fa-times"></i></div>
    </div>
  </div>
  <div v-if="data && data.footer && data.footer.mobile" :class="`${this.$adsClass} ${this.$adsClass}-fixbot for-mobile ft-box`">
    <div style="max-width: 1330px;width: 100%!important;position: relative;margin: 0 auto">
      <div v-for="(item,i) in data.footer.mobile" :key="i" :class="`${this.$adsClass}-inner ${item.class}`" >
        <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
          <img rel="nofollow" loading="lazy" :src="item.src"/></a>
      </div>
      <div class="rn-close remove-pr"><i class="fas fa-times"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsFooter",
  data(){
    return {route_name: this.$route.name}
  },
  computed: {
    data() {
      if(this.$route.name === 'match'){
        return this.$store.state.asd.live;
      }else{
        return this.$store.state.asd.home;
      }
    }
  },
  mounted() {
  },
}
</script>
