<template>
  <div class="app-body">
    <template v-if="['goto','start'].includes(routeName)">
      <router-view></router-view>
    </template>
    <template v-else>
      <AdsHead></AdsHead>
      <PageHeader></PageHeader>
      <div class="clearfix"></div>
      <router-view></router-view>
      <PageFooter></PageFooter>
      <LoginModal></LoginModal>
      <AdsSlide></AdsSlide>
      <AdsFooter></AdsFooter>
      <AdsPopup></AdsPopup>
      <div v-html="dynamicStyle"></div>
    </template>
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle';
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import LoginModal from "@/components/modals/LoginModal";
import AdsSlide from "@/components/ads/AdsSlide";
import AdsHead from "@/components/ads/AdsHead";
import AdsFooter from "@/components/ads/AdsFooter";
import AdsPopup from "@/components/ads/AdsPopup";
// eslint-disable-next-line no-unused-vars
import UserService, {TOKEN_KEY} from "@/services/UserService";
import {getCurrentInstance} from 'vue'
import {mapState, useStore} from "vuex";
import * as $ from "jquery";

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
    LoginModal,
    AdsSlide,
    AdsHead,
    AdsFooter,
    AdsPopup,
  },
  data() {
    return {
      dynamicStyle:
`<style>
    .${this.$adsClass}{ position: relative;}
    .${this.$adsClass}.for-mobile{display: none;}
    .${this.$adsClass}-inner a{display: block}
    a.${this.$adsClass}-inner{display: block}
    .${this.$adsClass} .${this.$adsClass}-inner{background-color: #000; text-align: center; margin: 0 auto; position: relative;}
    .${this.$adsClass} .${this.$adsClass}-inner img{width: 100%; height: 100%;}
    .${this.$adsClass} .${this.$adsClass}-inner a{display: block;}
    .${this.$adsClass}-top{background-color: rgba(0,0,0,.4);}
    .${this.$adsClass}-fixbot{ position: fixed; z-index: 100; bottom: 0; left: 0; right: 0;}
    .${this.$adsClass}-fixbot .${this.$adsClass}-inner{ margin: 0 auto; position: relative;}
    .${this.$adsClass}-fixside{position: fixed; z-index: 100; top: 50%; transform: translateY(-50%);}
    .${this.$adsClass}-fixside.to-left{left: calc(50% - 845px);}
    .${this.$adsClass}-fixside.to-right{right: calc(50% - 845px);}
    .${this.$adsClass} .xclose{position: absolute; top: 3px; right: 3px; z-index: 9; width: 24px; line-height: 24px; text-align: center; background-color: #fff; color: #000; font-size: 14px; box-shadow: 0 0 10px 0 rgba(0,0,0,.3); border-radius: .2rem; cursor: pointer;}
    .${this.$adsClass}-dual{display: flex; justify-content: space-between; margin-bottom: 2px;}
    .${this.$adsClass}-dual .${this.$adsClass}{ width: calc(50% - 1px);}
    .${this.$adsClass}-single{margin-bottom: 2px;}
    .${this.$adsClass}-inner.size-1330{ width: 1330px;}
    .${this.$adsClass}-inner.size-1330x130{ width: 1330px; height: auto;}
    .${this.$adsClass}-inner.size-1330x90{ width: 1330px; height: auto;}
    .${this.$adsClass}-inner.size-1330x90.dup-element{ height: 182px;}
    .${this.$adsClass}-inner.size-1330x90.dup-element .${this.$adsClass}-link + .${this.$adsClass}-link{margin-top: 2px;}
    .${this.$adsClass}-inner.size-728{width: 100%}
    .${this.$adsClass}-inner.size-728x90{width: 100%; height: auto;max-height: 90px;}
    .${this.$adsClass}-inner.size-728x90 img{top: 0; left: 0; right: 0; bottom: 0; object-fit: contain;}
    .${this.$adsClass}-inner.size-728x90.dup-element{ height: auto; padding: 0;}
    .${this.$adsClass}-inner.size-728x90.dup-element .${this.$adsClass}-link{position: relative; width: 100%; height: 0; padding-bottom: 12.362%;}
    .${this.$adsClass}-inner.size-660x200 {position: relative;max-width: 660px; width: 100%}
    .${this.$adsClass}-inner.size-660x200 img{ top: 0; left: 0; right: 0; bottom: 0; object-fit: contain;}
    .${this.$adsClass}-inner.size-160x800{ width: 160px; height: auto;min-height: 400px}
    .${this.$adsClass}-inner.size-500x500{width: 500px; height: 500px;}
    .${this.$adsClass}-inner.size-500x500 img{width: 100%; height: 100%;max-height: 500px;max-width: 500px;}
    .${this.$adsClass}-single.for-mobile, .${this.$adsClass}-dual.for-mobile {display: none}
    @media screen and (max-width: 728px) {
      .${this.$adsClass}-single.for-mobile,.${this.$adsClass}-dual.for-mobile {display: block}
      .${this.$adsClass}-single.for-desktop,.${this.$adsClass}-dual.for-desktop {display: none}
    }
    @media screen and (max-width: 1720px){
      .${this.$adsClass}-inner.size-160x800{ width: 120px; height: auto;min-height: 400px}
      .${this.$adsClass}-fixside.to-left{left: calc(50% - 805px);}
      .${this.$adsClass}-fixside.to-right{right: calc(50% - 805px);}
    }
    @media screen and (max-width: 1620px){
      .${this.$adsClass}-fixside{display: none;}
    }
    @media screen and (max-height: 800px){
      .${this.$adsClass}-inner.size-160x800{ width: 120px; height: auto;min-height: 400px}
      .${this.$adsClass}-fixside.to-left{left: calc(50% - 805px);}
      .${this.$adsClass}-fixside.to-right{right: calc(50% - 805px);}
    }
    @media screen and (max-height: 620px){
      .${this.$adsClass}-fixside{display: none;}
    }
    @media screen and (max-width: 1329px){
      .${this.$adsClass}-inner.size-1330{width: 100%;}
      .${this.$adsClass}-inner.size-1330x130{width: 100%; height: 0; padding-bottom: 9.774%;}
      .${this.$adsClass}-inner.size-1330x130 img{ position: absolute; top: 0; left: 0; right: 0; bottom: 0; object-fit: contain;max-height: 130px}
      .${this.$adsClass}-inner.size-1330x90.dup-element{width: 100%; height: 0; padding-bottom: calc(13.533% + 2px);}
    }
    @media screen and (max-width: 860px){
      .${this.$adsClass}-dual{display: block; margin-bottom: 2px;}
      .${this.$adsClass}-dual .${this.$adsClass}{ width: 100%; margin-bottom: 2px;}
    }
    @media screen and (max-width: 728px){
      .${this.$adsClass}.for-mobile{display: block;}
      .${this.$adsClass}.for-desktop{display: none;}
    }
    .modal-${this.$adsClass} .modal-dialog{ width: auto !important;}
    .modal-${this.$adsClass} .modal-dialog .modal-content{border-radius: 0; padding: 0 !important; background-color: #000 !important; width: auto !important; margin: 0 auto !important;}
    .modal-${this.$adsClass} .xclose{ top: 3px; right: 3px; border-radius: 6px;}

    .${this.$adsClass}-top {background-color: ${this.$ads_color_top};}
    .${this.$adsClass}-inner{overflow: hidden;}
    .${this.$adsClass}-inner img {object-fit: fill !important;}

    .${this.$adsClass} .${this.$adsClass}-inner{background-color: rgba(70,70,70,0.2); text-align: center; margin: 0 auto; position: relative;overflow: hidden;}
    .${this.$adsClass}.${this.$adsClass}-fixside.to-left, .${this.$adsClass}.${this.$adsClass}-fixside.to-right {z-index: inherit}
    .${this.$adsClass}-inner.size-1330x100 {width: 1330px;height: 100px}
    .${this.$adsClass}-inner.size-1330x90 {width: 1330px;height: 90px}
    @media screen and (max-width: 1329px) {
      .${this.$adsClass}-inner.size-1330x100 {width: 100%;height: 0;padding-bottom: 8%}
      .${this.$adsClass}-inner.size-1330x100 img {position: absolute;top: 0;left: 0;right: 0;bottom: 0;object-fit: contain}
      .${this.$adsClass}-inner.size-1330x90 {width: 100%;height: 0;padding-bottom: 8%}
      .${this.$adsClass}-inner.size-1330x90 img {position: absolute;top: 0;left: 0;right: 0;bottom: 0;object-fit: contain;max-height: 90px}
    }

    @media screen and (max-width: 520px) {
      .modal-${this.$adsClass} .modal-dialog .modal-content {width:96%!important; }
      .modal-${this.$adsClass} .modal-dialog .modal-content .size-500x500 {width:100%; height:100%;}
    }
    .modal-open {
        overflow: auto !important;
        padding-right: 0 !important;
    }
    .${this.$modalClass} {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1050;
        display: none;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        outline: 0;
    }
    .${this.$modalClass}.show {
        background: rgba(0, 0, 0, 0.5);
    }
</style>`
    }
  },
  watch:{
    $route(to, from){
      if(to.name !== 'match'){
        if(this.player){
          console.log('clear player')
          if(this.peerInstance){
            try{
              this.peerInstance.detach(this.player)
            }catch (e){/**/}
          }
          this.$store.commit('match/setPlayer', null)
          this.$store.commit('match/setPeerInstance', null)
        }
      }
      if(from){
        $('#modalasd').modal('show');
      }
    }
  },
  setup() {

  },
  computed : mapState({
    player : state => state.match.player,
    peerInstance : state => state.match.peerInstance,
    routeName(){
      return this.$route.name;
    },
  }),
  async mounted() {
    if(!['/goto','/'].includes(window.location.pathname)){
      const app = getCurrentInstance()
      const themeId = app.appContext.config.globalProperties.$siteId
      const store = useStore()
      if (!store.state.asd.home) {
        setTimeout(function (){
          store.dispatch('asd/fetchHome', themeId)
        },0)
      }
      if (!store.state.asd.live) {
        setTimeout(function (){
          store.dispatch('asd/fetchLive', themeId)
        },0)
      }
      if (!store.state.asd.bet_host) {
        setTimeout(function (){
          store.dispatch('asd/fetchBetHost', themeId)
        },0)
      }

      setTimeout(() => {
        this.$store.dispatch('match/fetchLive')
      }, 100)

      setInterval(() => {
        this.$store.dispatch('match/fetchLive')
      }, 10 * 1000)

      if (!this.$store.getters['auth/isLoggedIn']) {
        const {
          // eslint-disable-next-line no-unused-vars
          user, access_token
        } = await UserService.fetchUser()

        /*console.log('app-user', {
          user, access_token
        })*/

        if (user) {
          this.$store.dispatch('auth/login', {user})
        }
      }

      setTimeout(function () {
        $('#modalasd').modal('show');
      },1000);

      window.setTimeout(function() {
        $('.remove-pr').click(function () {
          $(this).parents('.ft-box').remove();
        })
      }, 300);
      window.setTimeout(function() {
        $('.remove-pr').click(function () {
          $(this).parents('.ft-box').remove();
        })
      }, 500);
      window.setTimeout(function() {
        $('.remove-pr').click(function () {
          $(this).parents('.ft-box').remove();
        })
      }, 800);

      $("#m-menu").click(function () {
        $("body").toggleClass("body-fixed");
        $("#sidebar-menu").toggleClass("active");
        $("#sidebar-menu-mask").toggleClass("active");
      });
      $("#sidebar-menu-mask").click(function () {
        $("body").removeClass("body-fixed");
        $("#sidebar-menu").removeClass("active");
        $("#sidebar-menu-mask").removeClass("active");
      });
    }

  }
}
</script>

<style>
  /*@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css';*/
  /*@import 'https://use.fontawesome.com/releases/v5.3.1/css/all.css';*/
  /*@import '@/../public/bk/static/stylesheet/wrapper.css';*/
  /*@import '@/../public/bk/static/stylesheet/mobile.css';*/
  /*@import '@/../public/bk/static/stylesheet/custom.css';*/
</style>
