<template :key="route_name">
  <div v-if="data && data.chat_bot">
    <div v-if="data && data.chat_bot && data.chat_bot.pc" :class="`${this.$adsClass} for-desktop`" style="z-index:2;position: absolute;left:0;right:0;bottom:0;">
      <a v-for="(item,i) in data.chat_bot.pc" :key="i" :href="'/goto?url=' + encodeURIComponent(item.href)" target="_blank" rel="nofollow" style="width:100%;display:block;">
        <img :src="item.src" rel="nofollow" loading="lazy" style="width:100%">
      </a>
    </div>
    <div v-if="data && data.chat_bot && data.chat_bot.mobile" :class="`${this.$adsClass} for-mobile`" style="z-index:2;position: absolute;left:0;right:0;bottom:0;">
      <a v-for="(item,i) in data.chat_bot.mobile" :key="i" :href="'/goto?url=' + encodeURIComponent(item.href)" target="_blank" rel="nofollow" style="width:100%;display:block;">
        <img :src="item.src" rel="nofollow" loading="lazy" style="width:100%">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsChatBot",
  data(){
    return {route_name: this.$route.name}
  },
  computed: {
    data() {
      if(this.$route.name === 'match'){
        return this.$store.state.asd.live;
      }else{
        return this.$store.state.asd.home;
      }
    }
  },
}
</script>
