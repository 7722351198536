<template>
  <div v-if="fixture()" >
    <TournamentFixture v-for="(item, index) in fixture()"
                       :key="index"
                       :tournament="item.tournament"
                       :fixture="item.matches"
    ></TournamentFixture>
    <custom-link :to="{ name: 'fixture_day', params: { slug: date_slug } }"
                 class="load-more"><i class="fas fa-angle-down mr-2"></i>Xem thêm
    </custom-link>
  </div>
  <FixtureLoading v-else></FixtureLoading>
</template>

<script>
import TournamentFixture from "@/components/home/fixture/TournamentFixture";
import FixtureLoading from "@/components/loading/FixtureLoading";
import {mapState} from "vuex";
import {group_by_tournament} from "@/func/match.func";
import {ref} from "vue";
import * as moment from 'moment';
export default {
  name: "FixtureDate",
  props: [
      'date'
  ],
  data(){
    return {
      page: 1
    }
  },
  setup(props){
    const date_slug = ref('hom-nay')
    const today = moment().format('YYYYMMDD')
    if(props.date !== today){
      const parsed_date = moment(props.date, 'YYYYMMDD')
      if(parsed_date.isValid()){
        date_slug.value = `ngay-${parsed_date.format('DD-MM-YYYY')}`
      }
    }


    return {
      date_slug
    }

  },
  components:{
    TournamentFixture,
    FixtureLoading
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      live: state => state.match.live,
      date_matches: state => state.match.home_date_matches,
    }),
  },
  methods:{
    fixture() {
      let data = null
      if(this.date === 'live'){
        data =  group_by_tournament(this.live)
      }else {
        data = this.date_matches[this.date]
      }
      return data
      // if(data && data.length > 0){
      //   return data.slice(0, (this.page) * 10)
      // }
      // return null
    },
  }
}
</script>

<style scoped>

</style>