<template>
  <div class="xitem xitem-grid">
    <custom-link :to="{ name: 'match', params: { id: item.id, slug: item.slug } }" class="match-link" ></custom-link>
    <div  v-if="item.isLive()" class="stick stick-live"><i class="dot"></i>Live</div>
    <div class="xitem-header">
      <div class="xleague">{{ item.tournamentDetailText() }}</div>
    </div>
    <div class="xitem-main">
      <div class="team team-home">
        <div class="team-logo"><img class="team-logo-img" loading="lazy" :src="item.home.logo"></div>
        <div class="xname">
          <h3 class="team-name">{{ item.home.name }}</h3>
          <div class="xcards">
            <div class="xcard xcard-red"  v-for="card in item.home_red_cards_arr" :key="card"></div>
          </div>
        </div>
      </div>
      <div class="xinfo" v-if="item.match_status === 'live' || item.match_status === 'finished'">
        <div class="result">
          <div>{{ item.showHomeScore() }}</div>
          <div class="xspace">-</div>
          <div>{{ item.showAwayScore() }}</div>
        </div>
        <div  v-if="item.isLive()"  class="time-loaded">{{ item.time_str }}</div>
        <div v-if="item.isEnd()" class="time-loaded end">Hết giờ</div>
      </div>
      <div class="xinfo" v-if="item.match_status === 'pending'">
        <div class="status">{{ item.showTime() }}</div>
        <div class="detail">{{ item.showDate() }}</div>
      </div>

      <div class="team team-away">
        <div class="team-logo"><img class="team-logo-img"  loading="lazy" :src="item.away.logo"></div>
        <div class="xname">
          <h3 class="team-name">{{ item.away.name }}</h3>
          <div class="xcards">
            <div class="xcard xcard-red"  v-for="card in item.away_red_cards_arr" :key="card"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="xitem-bottom">
      <div class="xcommentator">
        <template v-if="item.commentators && item.commentators.length > 0" ><i class="fas fa-microphone mr-2"></i>{{ item.casterName() }}</template>
        </div>
      <div class="xitem-buttons">
        <div classw="ibs-live">
          <custom-link :to="{ name: 'match', params: { slug: item.slug, id: item.id } }" class="btn btn-sm">Trực tiếp</custom-link>
        </div>
        <div class="ibs-bet"><a :href="getBtnAdsBet()" target="_blank" rel="nofollow" class="btn btn-sm btn-betnow">Đặt cược</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchFeaturedItem",
  props: [
    "modelId"
  ],
  computed: {
    item() {
      return this.$store.state.match.matches["id_" + this.modelId];
    },
  },
  methods: {
    getTournamentText(){
      return this.item.tournament.name
    }
  }
}
</script>

<style scoped>

</style>