<template>
<!--  <a v-bind="$attrs" ><slot/></a>-->
<!--  <router-link v-bind="$attrs" :to="$attrs.href"><slot/></router-link>-->
  <a :href="url" v-bind="$attrs"><slot/></a>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "custom-link",
  props:[
    'to',
    'href'
  ],
  computed:{
    url(){
      if(this.href){
        return this.href
      }
      return this.$router.resolve(this.to).href
    }
  }
}
</script>

<style scoped>

</style>