import ApiService from "@/services/ApiService";
import store from '../store'

export const TOKEN_KEY = 'vb_token'
export const AUTH_DOMAIN = window.auth ?  window.auth : 'https://auth2.vebotv.me';
export default class UserService {

    static getToken(){
        return localStorage.getItem(TOKEN_KEY) || window.getCookie(TOKEN_KEY);
    }
    static saveToken(access_token){
        localStorage.setItem(TOKEN_KEY, access_token);
        window.setCookie(TOKEN_KEY, access_token, 365);
    }
    static removeToken(){
        localStorage.removeItem(TOKEN_KEY);
        window.delCookie(TOKEN_KEY);
    }
    static async login(email, password){
        //const store    = useStore();
        const response = await ApiService.post(`${AUTH_DOMAIN}/v1/auth/login`,{
            email, password
        })
        if (response && response.data) {
            if(response.data.chat_urls){
                await store.dispatch('auth/setChatUrls', response.data.chat_urls)
            }
            await store.dispatch('auth/setChatToken', response.data.new_chat_token||'')
        }
        if(response.data.status === 1){
            const user = response.data.user;
            const access_token = response.data.token;
            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }

            //const user = response.data.user;

            /*const {
                user, access_token
            } = response.data.data

            return {
                user, access_token
            }*/
        }else {
            throw new Error(response.data.message)
        }

    }
    static async logout(){
        //const store    = useStore();
        try {
            this.removeToken();

            const response = await ApiService.get(`${AUTH_DOMAIN}/v1/auth/logout`);
            if (response && response.data) {
                if(response.data.chat_urls){
                    await store.dispatch('auth/setChatUrls', response.data.chat_urls)
                }
                await store.dispatch('auth/setChatToken', response.data.new_chat_token||'')
            }
        }catch (e) {
            //
        }
        localStorage.removeItem(TOKEN_KEY)
        return true
    }
    static async fetchUserFromToken(){
        const response = await ApiService.get(`${AUTH_DOMAIN}/v1/user/profile`);
        if (response && response.data) {
            if(response.data.chat_urls){
                await store.dispatch('auth/setChatUrls', response.data.chat_urls)
            }
            await store.dispatch('auth/setChatToken', response.data.new_chat_token||'')
        }
        if(response.data.status === 1){
            const user = response.data.user;
            const access_token = response.data.token;
            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }
            /*const {
                user, access_token
            } = response.data.data

            return {
                user, access_token
            }*/
        }
        return {
            user: null, access_token: null
        }
    }
    static async fetchUserFromCookie(){
        //const store = useStore();
        const response = await ApiService.get(`${AUTH_DOMAIN}/v1/user/profile-cookie`);
        if (response && response.data) {
            if(response.data.chat_urls){
                await store.dispatch('auth/setChatUrls', response.data.chat_urls)
            }
            await store.dispatch('auth/setChatToken', response.data.new_chat_token||'')
        }
        if(response.data.status === 1){
            const user = response.data.user;
            const access_token = response.data.token;
            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }

            /*const {
                user, access_token
            } = response.data.data

            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }*/
        }
        return {
            user: null, access_token: null
        }
    }
    static async fetchUser(){
        if(this.getToken()){
            return this.fetchUserFromToken();
        }else {
            return this.fetchUserFromCookie();
        }
    }
    /*static async updateInfo(data){
        try{
            const response = await ApiService.post(`${AUTH_DOMAIN}/v1/user/profile`,data)
            return response.data
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }
    static async changePassword(password, new_password){
        try{
            const response = await ApiService.post(`${AUTH_DOMAIN}/v1/user/change-password`, {
                password,
                new_password
            })
            return response.data
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }
    static async uploadAvatar(form){
        try{
            const response = await ApiService.vueInstance.axios.post(`${AUTH_DOMAIN}/upload`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return {
                status : 1,
                data: response.data.data
            }
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }*/


}
