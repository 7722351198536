<template>
  <AdsRow1></AdsRow1>
  <div id="main-wrapper">
    <div class="container">

      <form class="search-box" :action="$router.resolve({ name: 'xemlai_search'}).href" method="get">
        <i class="fa fa-search"></i>
        <input required name="q" :value="this.query" type="text" class="form-control" placeholder="Tìm kiếm xem lại trận đấu..." />
        <button class="btn btn-primary">Tìm kiếm</button>
      </form>

      <div class="box box-news">
        <div class="box-header">
          <h1 class="headlineA1">Kết quả tìm kiếm xem lại bóng đá: {{this.query}}</h1>
          <div id="content_top" style="margin: 8px 0"></div>
        </div>
        <div class="box-content">
          <div class="mw-w-sidebar is-basic">
            <div class="main-left">
              <div class="news-list news-list-page">
                <XemlaiGrid :data="list" :show_cat="false"></XemlaiGrid>
                <div class="clearfix"></div>
              </div>
            </div>

            <div class="sidebar-right">
              <BetHostSmall></BetHostSmall>
              <AdsTable1></AdsTable1>
            </div>

            <div class="clearfix"></div>
          </div>
        </div>
        <div id="content_bot" style="margin: 10px 0 20px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import AdsRow1 from "@/components/ads/AdsRow1";
import BetHostSmall from "@/components/ads/BetHostSmall";
import AdsTable1 from "@/components/ads/AdsTable1";
import ApiService from "@/services/ApiService";
import XemlaiGrid from "@/components/news/XemlaiGrid.vue";
export default {
  name      : "XemlaiSearch",
  components: {
    XemlaiGrid,
    AdsRow1,
    BetHostSmall,
    AdsTable1,
  },
  data() {
    return {
      query: null,
      limit    : 0,
      list     : [],
    }
  },

  async mounted() {
    this.query = this.$route.query.q;
    const [
      response,
      // eslint-disable-next-line no-unused-vars
      //meta,
    ] = await Promise.all([
      ApiService.get(`/api/news/${this.$siteId}/search/xemlai/${this.$route.query.q}`),
      //this.getMeta('news', `${this.$route.params.page || 1}`)
    ]);

    if (response && response.data && response.data.data) {
      this.list      = response.data.data.list;
      this.limit     = response.data.data.limit;
      this.query     = response.data.data.query;
    }
  },
}
</script>
