<template :key="route_name">
  <div v-if="ads && ads.table1" class="vebo-sp">
    <div v-for="(item,i) in ads.table1" :key="i" :class="`${this.$adsClass} mb-2`">
        <div :class="`${this.$adsClass}-inner size-660x200`">
          <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
            <img rel="nofollow" loading="lazy" :src="item.src"/></a>
        </div>
    </div>
  </div>
  <div v-else class="vebo-sp">
    <div class="mmo mb-2">
      <div class="mmo-inner size-660x200"></div>
    </div>
    <div class="mmo mb-2">
      <div class="mmo-inner size-660x200"></div>
    </div>
    <div class="mmo mb-2">
      <div class="mmo-inner size-660x200"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsTable1",
  data(){
    return {route_name: this.$route.name}
  },
  computed: {
    ads() {
      if(this.$route.name === 'match'){
        return this.$store.state.asd.live;
      }else{
        return this.$store.state.asd.home;
      }
    }
  },
  mounted() {
  }
}
</script>
