<template>
  <div id="review">
    <div class="container">
      <div class="box box-review mb-0">
        <div class="box-header">
          <h2 class="headlineA1"><custom-link :to="{ name: 'news'}" title="Nhận định, soi kèo, tin tức bóng đá mới nhất">Tin tức bóng đá hôm nay</custom-link></h2>
        </div>
        <div class="box-content">
          <div class="news-list">
            <NewsGridList :data="data" :show_cat="false"></NewsGridList>
            <div class="clearfix"></div>
          </div>
          <custom-link :to="{ name: 'news'}" class="load-more btn-blank"><i class="fas fa-angle-down mr-2"></i>Xem thêm</custom-link>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import NewsGridList from "@/components/news/NewsGridList";
export default {
  name: "NewsBlock",
  components: {
    NewsGridList
  },
  data () {
    return {
      data: []
    }
  },
  async mounted() {
    const response = await ApiService.get(`/api/news/${this.$siteId}/home`)
    if(response && response.data && response.data.data){
      this.data = response.data.data
    }
  },
}
</script>