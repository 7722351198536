<template>
  <div class="ajax-loading is-load-focus">
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
    <div class="vir-item"></div>
  </div>
</template>

<script>
export default {
  name: "MatchFeaturedPlaceHolder"
}
</script>
