<template>
  <AdsRow1></AdsRow1>
  <div id="main-wrapper">
    <div class="container">
      <ResultList></ResultList>
    </div>
  </div>
  <AdsRow4></AdsRow4>
</template>

<script>
import ResultList from "@/components/result/ResultList";
import AdsRow1 from "@/components/ads/AdsRow1";
import AdsRow4 from "@/components/ads/AdsRow4";

export default {
  name: "ResultPage",
  components: {
    ResultList,
    AdsRow1,
    AdsRow4,
  },
}
</script>