<template>
  <ul class="no-bullets">
    <li :class="{active : data.route_current === 'league_overview'}">
      <custom-link :to="{ name: 'league_overview', params: { slug: data.slug, id: data.id } }" class="cdm-link">Tổng Quan</custom-link>
    </li>
<!--    <li :class="{active : data.route_current === 'league_standing'}">
      <custom-link :to="{ name: 'league_standing', params: { slug: data.slug, id: data.id } }" class="cdm-link">Bảng xếp hạng</custom-link>
    </li>-->
    <li :class="{active : data.route_current === 'league_result'}">
      <custom-link :to="{ name: 'league_result', params: { slug: data.slug, id: data.id } }" class="cdm-link">Kết quả</custom-link>
    </li>
    <li :class="{active : data.route_current === 'league_schedule'}">
      <custom-link :to="{ name: 'league_schedule', params: { slug: data.slug, id: data.id } }" class="cdm-link">Lịch thi đấu</custom-link>
    </li>
  </ul>
</template>

<script>
export default {
  name : "TournamentTabs",
  props: [
    'data'
  ],
}
</script>