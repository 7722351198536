<template>
  <div v-if="data" :class="this.$modalClass +' fade modal-' + this.$adsClass" id="modalasd" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="rn-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></div>
        <a :href="'/goto?url=' + encodeURIComponent(data.href)" :target="data.target" rel="nofollow" :class="`${this.$adsClass}-inner size-500x500`">
          <img rel="nofollow" loading="lazy" :src="data.src" title=""></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsPopup",
  computed: {
    data() {
      if(this.$route.name === 'match'){
        if(this.$store.state.asd.live){
          let asd = this.$store.state.asd.live.popup
          asd =  asd[Math.floor(Math.random()*asd.length)];
          return asd;
        }else{
          return null;
        }
      }else{
        if(this.$store.state.asd.home){
          let asd = this.$store.state.asd.home.popup
          asd =  asd[Math.floor(Math.random()*asd.length)];
          return asd;
        }else{
          return null;
        }
      }
    }
  },
  mounted() {
  }
}
</script>
