<template :key="route_name">
  <div v-if="data && data.head">
    <div v-if="data.head.pc" :class="`${this.$adsClass} ${this.$adsClass}-top for-desktop`">
      <template v-for="(item,i) in data.head.pc" :key="i" >
        <div v-if="item.html" v-html="item.html"></div>
        <div v-else :class="(item.class.replace('mmo-',this.$adsClass+'-'))">
          <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
            <img rel="nofollow" loading="lazy" :src="item.src"/></a>
        </div>
      </template>
    </div>
    <div v-if="data.head.mobile" :class="`${this.$adsClass} ${this.$adsClass}-top for-mobile`">
      <template v-for="(item,i) in data.head.mobile" :key="i" >
        <div v-if="item.html" v-html="item.html"></div>
        <div v-else :class="(item.class.replace('mmo-',this.$adsClass+'-'))">
          <a :href="'/goto?url=' + encodeURIComponent(item.href)" :target="item.target" rel="nofollow">
            <img rel="nofollow" loading="lazy" :src="item.src"/></a>
        </div>
      </template>
    </div>
  </div>
  <div v-else>
    <div :class="`${this.$adsClass} ${this.$adsClass}-top for-desktop`">
      <div :class="`${this.$adsClass}-inner size-1330x130`"></div>
    </div>
    <div :class="`${this.$adsClass} ${this.$adsClass}-top for-mobile`">
      <div :class="`${this.$adsClass}-inner size-728x90`"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdsHead",
  data() {
    return {route_name: this.$route.name}
  },
  computed: {
    data() {
      if (this.$route.name === 'match') {
        return this.$store.state.asd.live;
      } else {
        return this.$store.state.asd.home;
      }
    }
  },
}
</script>
