import * as moment from 'moment';
export class UserModel {
    id
    name
    email
    avatar
    join_date
    join_time
    chat_url
    get local_join_date(){
        return moment(this.join_time).format('DD/MM/YYYY')
    }
    constructor(data) {
        Object.assign(this, data)
    }
}