import {createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/pages/HomePage";
import FixturePage from "@/pages/FixturePage";
import ResultPage from "@/pages/ResultPage";
import MatchPage from "@/pages/MatchPage";
import TournamentPage from "@/pages/TournamentPage";
import ProfilePage from "@/pages/ProfilePage";
import store from '../store'
import UserService from "@/services/UserService";
import * as $ from 'jquery'

import StartPage from "@/pages/StartPage";
import GotoPage from "@/pages/GotoPage";

import NewsPage from "@/pages/news/NewsPage";
import NewsDetailPage from "@/pages/news/NewsDetailPage";
import NewsSearch from "@/pages/news/NewsSearch";

import HighlightPage from "@/pages/news/HighlightPage";
import HighlightDetailPage from "@/pages/news/HighlightDetailPage";
import HighlightSearch from "@/pages/news/HighlightSearch";

import SoikeoPage from "@/pages/news/SoikeoPage";
import SoikeoDetailPage from "@/pages/news/SoikeoDetailPage";
import SoikeoSearch from "@/pages/news/SoikeoSearch";

import XemlaiPage from "@/pages/news/XemlaiPage";
import XemlaiDetailPage from "@/pages/news/XemlaiDetailPage";
import XemlaiSearch from "@/pages/news/XemlaiSearch";

import AuthorDetailPage from "@/pages/AuthorDetailPage";
import FixPageVue from '@/pages/FixPage.vue';
import AppPageVue from '@/pages/AppPage.vue';

const routes = [
    {
        path: '/',
        name: 'start',
        component: StartPage
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage
    },
    {
        path: '/user/profile',
        name: 'profile',
        component: ProfilePage,
        meta: {requiresAuth: true},
    },
    {
        path: '/lich-thi-dau-bong-da',
        name: 'fixture',
        component: FixturePage
    },
    {
        path: '/lich-thi-dau-bong-da/:slug',
        name: 'fixture_day',
        component: FixturePage
    },
    {
        path: '/ket-qua-bong-da',
        name: 'result',
        component: ResultPage
    },
    {
        path: '/ket-qua-bong-da/:slug',
        name: 'result_day',
        component: ResultPage
    },
    {
        path: '/giai-dau/bang-xep-hang-:slug-:id([a-zA-Z0-9]*)',
        name: 'league_standing',
        component: TournamentPage
    },
    {
        path: '/giai-dau/ket-qua-:slug-:id([a-zA-Z0-9]*)',
        name: 'league_result',
        component: TournamentPage
    },
    {
        path: '/giai-dau/lich-thi-dau-:slug-:id([a-zA-Z0-9]*)',
        name: 'league_schedule',
        component: TournamentPage
    },
    {
        path: '/giai-dau/:slug-:id([a-zA-Z0-9]*)',
        name: 'league_overview',
        component: TournamentPage
    },
    {
        path: '/truc-tiep/:slug-:id([a-zA-Z0-9]*)',
        name: 'match',
        component: MatchPage
    },
    {
        path: '/tin-tuc',
        name: 'news',
        component: NewsPage
    },
    {
        path: '/highlight',
        name: 'highlight',
        component: HighlightPage
    },
    {
        path: '/soi-keo',
        name: 'soikeo',
        component: SoikeoPage
    },
    {
        path: '/xem-lai',
        name: 'xemlai',
        component: XemlaiPage
    },
    {
        path: '/tin-tuc/tim-kiem',
        name: 'news_search',
        component: NewsSearch
    },
    {
        path: '/highlight/tim-kiem',
        name: 'highlight_search',
        component: HighlightSearch
    },
    {
        path: '/soi-keo/tim-kiem',
        name: 'soikeo_search',
        component: SoikeoSearch
    },
    {
        path: '/xem-lai/tim-kiem',
        name: 'xemlai_search',
        component: XemlaiSearch
    },
    {
        path: '/tin-tuc/trang-:page([0-9]*)',
        name: 'news_page',
        component: NewsPage
    },
    {
        path: '/highlight/trang-:page([0-9]*)',
        name: 'highlight_page',
        component: HighlightPage
    },
    {
        path: '/soi-keo/trang-:page([0-9]*)',
        name: 'soikeo_page',
        component: SoikeoPage
    },
    {
        path: '/xem-lai/trang-:page([0-9]*)',
        name: 'xemlai_page',
        component: XemlaiPage
    },
    {
        path: '/tin-tuc/:slug-:id([a-zA-Z0-9]*)',
        name: 'news_detail',
        component: NewsDetailPage
    },
    {
        path: '/highlight/:slug-:id([a-zA-Z0-9]*)',
        name: 'highlight_detail',
        component: HighlightDetailPage
    },
    {
        path: '/soi-keo/:slug-:id([a-zA-Z0-9]*)',
        name: 'soikeo_detail',
        component: SoikeoDetailPage
    },
    {
        path: '/xem-lai/:slug-:id([a-zA-Z0-9]*)',
        name: 'xemlai_detail',
        component: XemlaiDetailPage
    },
    {
        path: '/goto',
        name: 'goto',
        component: GotoPage,
    },
    {
        path: '/tac-gia/:slug',
        name: 'author_detail',
        component: AuthorDetailPage
    },
    {
        path: '/tac-gia/:slug',
        name: 'author_detail',
        component: AuthorDetailPage
    },
    {
        path: "/fix",
        name: "fix",
        component: FixPageVue,
      },
      {
        path: "/pc",
        name: "pc",
        component: AppPageVue,
      },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        //return { top: 0 }
        return {
            top: 0,
            behavior: 'smooth',
        }
    },

})
router.beforeEach(async (to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/isLoggedIn']) {
            next()
            return
        }else {
            const {
                user
            } = await UserService.fetchUser()
            if (user) {
                store.dispatch('auth/login', {
                    user
                })
                next()
                return
            }
        }
        next('/?login=true')
        setTimeout(function (){
            try {
                $('#modallogin').modal('show');
            }catch (e) {
                //
            }
        },100)
        return
    } else {
        next()
    }
})
export default router