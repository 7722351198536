<template>
  <meta http-equiv="refresh" :content="'0; URL='+url"/>
  <div style="width: 100%!important;z-index: 1000;position: relative;margin: 0 auto;text-align: center;height: 100vh;line-height: 100vh;font-size: 18px;font-weight:bold">Chờ xíu...</div>
</template>

<script>
import {useRoute} from "vue-router";
export default {
  name: "GotoPage",
  components: {

  },
  setup(){
    const route = useRoute();
    let url = route.query.url ? route.query.url : window.location.origin;
    url = decodeURIComponent(url);
    return {
      url: url
    }
  },

}
</script>